import React from "react";
import { Redirect, Route } from "react-router-dom";
import {useAuthState} from "../сontexts/auth";

const AppRoutes = ({ component: Component, path, needAuth, needAdminPerm, ...rest }) => {
    const userDetails = useAuthState();
    const authToken = userDetails.token;
    const adminPermission = userDetails.user && userDetails.user.permissions.includes('admin');

    const loginRedirect = <Redirect to='/login' />;
    const adminRedirect  = <Redirect to='/admin' />;
    const homeRedirect  = <Redirect to='/' />;
    const routing = (props) => <Component {...props} />;

    return (
        <Route
            path={path}
            render={props =>
                needAuth ? (
                    authToken ? (
                        needAdminPerm ? (
                            adminPermission ? (
                                routing(props)
                            ) : (
                                homeRedirect
                            )
                        ) : (
                            adminPermission ? (
                                adminRedirect
                            ) : (
                                routing(props)
                            )
                        )
                    ) : (
                        loginRedirect
                    )
                ) : (
                    authToken ? (
                        homeRedirect
                    ) : (
                        routing(props)
                    )
                )
            }
            {...rest}
        />
    )
};

export default AppRoutes;