import { makeStyles } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDateParamsUrl } from '../useDateParamsUrl';
import { iterationStyles } from './Iteration.styles';

const useStyles = makeStyles(iterationStyles());

export function Iteration({ id, i, active, type, amount, onSetIteration }) {
    const styles = useStyles();

    return (
        <button
            onClick={() => onSetIteration(i)}
            className={[`${styles.host} ${active && styles.active}`]}
        >
            {type === 1 && kolibriMake(i, amount > 1)}
            {type === 2 && spiroMake(i, amount)}
        </button>
    );
}

const kolibriMake = (i, isFirst) => {
    return (isFirst ?
        i === 1 ? <span /> : i - 1
    : i)
};

const spiroMake = (i, amount) => {
    return (amount > 1 && i === amount ? <div /> : i)
};
