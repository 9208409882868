import { theme } from '../../../theme';
import avgActive from '../../../assets/images/average_white.png';
import avgDefault from '../../../assets/images/average_grey.png';
import bestActive from '../../../assets/images/best_white.png';
import bestDefault from '../../../assets/images/best_grey.png';

export const iterationStyles = (t = theme) => ({
    host: {
        border: 'none',
        borderRadius: '50%',
        background: t.palette.primary.mainBackground,
        padding: '0.8em',
        boxShadow: t.palette.primary.mainShadow,
        width: '3.3em',
        height: '3.3em',
        textAlign: 'center',
        textDecoration: 'none',
        color: t.palette.primary.main,
        cursor: 'pointer',

        '& span': {
            display: 'block',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${avgDefault})`,
            backgroundPosition: 'center',
            backgroundSize: '20px',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(30deg)',
        },

        '& div': {
            display: 'block',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${bestDefault})`,
            backgroundPosition: 'center',
            backgroundSize: '20px',
            backgroundRepeat: 'no-repeat',
        },

        '&:focus': {
            outline: '0 none',
        },
        '&:hover': {
            background: t.palette.primary.main,
            color: t.palette.primary.mainBackground,

            '& span': {
                backgroundImage: `url(${avgActive})`,
                backgroundSize: '20px',
            },

            '& div': {
                backgroundImage: `url(${bestActive})`,
                backgroundSize: '20px',
            },
        },
    },
    active: {
        background: t.palette.primary.main,
        color: t.palette.primary.mainBackground,

        '& span': {
            backgroundImage: `url(${avgActive})`,
            backgroundSize: '20px',
        },

        '& div': {
            backgroundImage: `url(${bestActive})`,
            backgroundSize: '20px',
        },
    },
});
