import { theme } from '../../../theme';

export const iterationsStyles = (t = theme) => ({
    host: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: '0.2em 0.3em',
        },
    },
});
