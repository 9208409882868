import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import {
    ArrowDownward,
    Clear,
    Check,
    AddBox,
    DeleteOutline,
} from '@material-ui/icons';
import SpirosStyles from './Spiros.styles';
import AdminApi from '../../../api/AdminApi';

const useStyles = makeStyles(SpirosStyles);
const SPIROS_LIST_TYPE = 'spiroSerials';

const Spiros = () => {
    const styles = useStyles();
    const { t } = useTranslation();

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => (
            <ArrowDownward {...props} ref={ref} />
        )),
        Check: forwardRef((props, ref) => (
            <Check
                {...props}
                classes={{ root: styles.spiros__icon }}
                ref={ref}
            />
        )),
        Clear: forwardRef((props, ref) => (
            <Clear
                {...props}
                classes={{ root: styles.spiros__icon }}
                ref={ref}
            />
        )),
        Delete: forwardRef((props, ref) => (
            <DeleteOutline
                {...props}
                classes={{ root: styles.spiros__icon }}
                ref={ref}
            />
        )),
    };

    const columns = [
        {
            title: t('common.serialNumber'),
            field: 'value',
        },
        {
            title: t('common.email'),
            render: (rowData) =>
                rowData.email.join(', '),
        }
    ];

    const [data, setData] = useState([]);

    useEffect(() => {
        loadTableData();
    }, []);

    const loadTableData = () => {
        return AdminApi.getWhiteList(SPIROS_LIST_TYPE)
            .then((result) => {
                setData(result.data.result);
            })
            .catch((e) => console.log(e));
    };

    const onRowAdd = (data) => {
        return AdminApi.saveToWhiteList(data.value, SPIROS_LIST_TYPE)
            .then(loadTableData)
            .catch((e) => console.log(e));
    };

    const onRowDelete = (data) => {
        return AdminApi.deleteFromWhiteList(data.value, SPIROS_LIST_TYPE)
            .then(loadTableData)
            .catch((e) => console.log(e));
    };

    return (
        <section className={styles.spiros}>
            <MaterialTable
                options={{
                    search: false,
                    paging: false,
                    actionsColumnIndex: -1,
                }}
                title={t('adminPage.spiroSerials')}
                icons={tableIcons}
                columns={columns}
                data={data}
                editable={{
                    onRowAdd,
                    onRowDelete,
                }}
            />
        </section>
    );
};

export { Spiros };
