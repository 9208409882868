const styles = () => ({
    box: {
        backgroundColor: '#fff',
    },
    wrapper: {
        display: 'flex',
        padding: '1.75em 0.875em',
        flexGrow: 1,
    },
    leftContent: {
        display: 'flex',
        flex: '1 0 0',
        flexDirection: 'column',
        marginRight: '1em',
    },
    deviceContent: {
        display: 'flex',
        flex: '4 0 0',
        flexDirection: 'column',
        '& >*:first-child': {
            marginBottom: '2em',
        },
    },
    inner: {
        '&:not(:last-child)': {
            marginBottom: '1.5em',
        },
    },
    shadowedContainer: {
        boxShadow: '0px 4px 8px 0px rgba(158,161,161,0.4)',
        flexGrow: 1,
        marginTop: 30,
        maxHeight: '330px',
        padding: '15px 50px 15px 20px;',
    },
});

export default styles;
