import React from 'react';
import { patientScheduleStyles } from './PatientSchedule.styles';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(patientScheduleStyles);

const PatientScheduleItem = ({ section }) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const listOfDays = section.days.split(',').sort((a, b) => a - b);

    return (
        <div className={styles.scheduleContainer}>
            <p className={styles.time}>{section.time}</p>
            <div className={styles.scheduleHeaderContainer}>
                {listOfDays.length === 7 ? (
                    <span className={styles.days}>{t('treatment.daily')}</span>
                ) : (
                    listOfDays.map((item, i) => {
                        return (
                            <span key={i} className={styles.days}>
                                {t(`treatment.days.day${item}`)}
                            </span>
                        );
                    })
                )}
            </div>
        </div>
    );
};

export { PatientScheduleItem };
