import React from 'react';

const ID = 'line-chart-blue';
const lineBlueFilter = `url(#${ID})`;

const LineBlueFilter = () => {
    return (
        <filter id={ID} height="200%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="7" result="blur" />
            <feOffset in="blur" dx="0" dy="7" result="offsetBlur" />
            <feFlood
                floodColor="#2089a3"
                floodOpacity="0.9"
                result="offsetColor"
            />
            <feComposite
                in="offsetColor"
                in2="offsetBlur"
                operator="in"
                result="offsetBlur"
            />
            <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
            </feMerge>
        </filter>
    );
};

export { LineBlueFilter, lineBlueFilter };
