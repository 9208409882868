const styles = (theme) => {
    return {
        admin: {
            display: 'flex',
            width: '100%',
        },

        admin__content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    };
};

export default styles;
