import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KOLIBRI_METER_ID } from './model';
import { Meter } from '../../../../components/Meter/Meter';
import { kolibriMeterStyles } from './KolibriMeter.styles';
import { KolibriLineChart } from './KolibriLineChart';
import { KolibriBarChart } from './KolibriBarChart';

const useStyles = makeStyles(kolibriMeterStyles());

export function KolibriMeter({ data }) {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <Meter id={KOLIBRI_METER_ID} title={t('homePage.kolibrimeterTitle')}>
            {data.charts.map((chart, i) => (
                <div key={i} className={styles.chart}>
                    <KolibriLineChart data={chart} />
                </div>
            ))}
            <div className={styles.adherence}>
                <KolibriBarChart data={data.adherence} />
            </div>
        </Meter>
    );
}
