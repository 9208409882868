import httpClient from './mainInstance';

const getWhiteList = (listType) =>
    httpClient.get('/admin/whitelist', {
        params: {
            listType,
        },
    });

const saveToWhiteList = (value, listType) =>
    httpClient.post('/admin/whitelist', null, {
        params: {
            value,
            listType,
        },
    });

const deleteFromWhiteList = (value, listType) =>
    httpClient.delete('/admin/whitelist', {
        params: {
            value,
            listType,
        },
    });

const getPatientsList = () => httpClient.get('/admin/patients');

const disablePatient = (id) => httpClient.put(`/admin/patients/${id}/disable`);

const enablePatient = (id) => httpClient.put(`/admin/patients/${id}/enable`);

const getPhysiciansList = () => httpClient.get('/admin/physicians');

const disablePhysician = (id) =>
    httpClient.put(`/admin/physicians/${id}/disable`);

const enablePhysician = (id) =>
    httpClient.put(`/admin/physicians/${id}/enable`);

const api = {
    getWhiteList,
    saveToWhiteList,
    deleteFromWhiteList,
    getPatientsList,
    disablePatient,
    enablePatient,
    getPhysiciansList,
    disablePhysician,
    enablePhysician,
};

export default api;
