import { makeStyles } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { dateStartFormat } from '../dateStartFormat';
import { useDateParamsUrl } from '../useDateParamsUrl';
import { selectionStyles } from './Selection.styles';

const useStyles = makeStyles(selectionStyles);

const Selection = ({ id, range, dayDate, day }) => {
    const styles = useStyles();

    const dayStr = dateStartFormat(dayDate);
    const active = dayStr === day;
    const label = dayDate.format('D');
    const monthLabel = label === '1' ? dayDate.format('MMM YYYY') : '';

    return (
        <NavLink
            to={useDateParamsUrl(id, { day: dayStr })}
            className={`${styles.host} ${range}`}
            isActive={() => active}
        >
            <span className={styles.label}>{label}</span>
            <span className={styles.labelHover}>{label}</span>
            {monthLabel && (
                <span className={styles.monthLabel}>{monthLabel}</span>
            )}
        </NavLink>
    );
};

export { Selection };
