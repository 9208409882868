import { theme } from '../../../theme';

export const spiroStyles = (t = theme) => ({
    host: {},
    meterPanel: {
        display: 'flex',
        '& >*': {
            '&:first-child': {
                flexGrow: 3,
                marginRight: '2em',
                maxWidth: 'calc(75% - 2em)',
            },
            '&:last-child': {
                flexGrow: 1,
                alignSelf: 'flex-start',
                maxWidth: '25%',
            },
        },
    },
});
