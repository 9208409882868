import { makeStyles } from '@material-ui/core';
import { kolibriStyles } from './Kolibri.styles';
import { KolibriDailyData } from './KolibriDailyData/KolibriDailyData';
import { KolibriMeter } from './KolibriMeter/KolibriMeter';
import { useKolibriData } from './useKolibriData';
import { useKolibriDayData } from './useKolibriDayData';

const useStyles = makeStyles(kolibriStyles());

export function Kolibri({ patientId }) {
    const styles = useStyles();
    const { data } = useKolibriData(patientId);
    const { data: dayData } = useKolibriDayData(patientId);

    return (
        data && (
            <div className={styles.meterPanel}>
                <KolibriMeter data={data} />
                <KolibriDailyData data={dayData} />
            </div>
        )
    );
}
