import { METER_DATE_START_URL_KEY } from './models/dateStart';
import { METER_DAY_URL_KEY } from './models/day';
import { METER_RANGE_URL_KEY } from './models/range';
import { useDateParams } from './useDateParams';
import { useBuildUrlWithAdditionalSearchParameters } from '../../utils/url/useBuildUrlWithAdditionalSearchParameters';
import { METER_ITERATION_URL_KEY } from './models/iteration';

/**
 * Builds url with date params, it uses current url and updates it
 */
const useDateParamsUrl = (id, paramsVar) => {
    const params = {
        ...useDateParams(id),
        ...paramsVar,
    };
    return useBuildUrlWithAdditionalSearchParameters({
        [[METER_DATE_START_URL_KEY, id].join('_')]: params.dateStart,
        [[METER_RANGE_URL_KEY, id].join('_')]: params.range,
        ...(paramsVar.day
            ? { [[METER_DAY_URL_KEY, id].join('_')]: params.day }
            : null),
        ...(paramsVar.iteration
            ? { [[METER_ITERATION_URL_KEY, id].join('_')]: params.iteration }
            : null),
    });
};

export { useDateParamsUrl };
