import React from 'react';
import { patientScheduleStyles } from './PatientSchedule.styles';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {PatientScheduleItem} from './PatientScheduleItem';

const useStyles = makeStyles(patientScheduleStyles);

const PatientTreatmentItem = ({ treatment }) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div className={styles.treatmentContainer}>
            <h5 className={styles.treatmentName}>
                {treatment['treatment-module-type'].defaultName}
            </h5>
            <div className={styles.scheduleListContainer}>
                {(!treatment.schedule || (treatment.schedule && !treatment.schedule.length)) && (
                    <div className={styles.noSchedule}>{t('treatment.noSchedule')}</div>
                )}
                {treatment.schedule && treatment.schedule.length > 0 && treatment.schedule.map((item, i) => (
                    <PatientScheduleItem key={i + item.time} section={item}/>
                ))}
            </div>
        </div>
    );
};

export { PatientTreatmentItem };
