import { theme } from '../../../../theme';

export const kolibriMeterStyles = (t = theme) => ({
    host: {},
    chart: {
        height: '8em',
        padding: '1.25em 0 0',
        '& svg.recharts-surface': {
            overflow: 'visible',
            '& .recharts-cartesian-axis-tick': {
                fontSize: '0.75em',
                color: t.palette.primary.chart.spiro.axisLabel,
            },
        },
        marginLeft: '-1.888em',
        '& .recharts-legend-item-text': {
            fontWeight: 'bold',
            color: t.palette.primary.chart.spiro.axisLabel,
            whiteSpace: 'nowrap',
            marginRight: '-2em',
        },
    },
    adherence: {
        margin: '0 -1.888em 0 -1.888em',
    },
});
