import { theme } from '../../../theme';

const dateStartStyles = (t = theme) => ({
    host: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            borderWidth: '0 0 0.125em 0.125em',
            borderStyle: 'solid',
            borderColor: t.palette.primary.main,
            boxSizing: 'border-box',
            width: '0.938em',
            height: '0.938em',
        },
        '&.prev': {
            '& span': {
                transform: 'rotate(45deg)',
            },
        },
        '&.next': {
            '& span': {
                transform: 'rotate(225deg)',
            },
        },
        '&:hover': {
            '& span': {
                borderWidth: '0 0 0.25em 0.25em',
            },
        },
    },
});

export { dateStartStyles };
