import { theme } from '../../../theme';

const rangeSelectStyles = (t = theme) => ({
    host: {
        color: t.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& a': {
            color: t.palette.primary.main,
            textDecoration: 'none',
            padding: '0 0.5em',
            textTransform: 'lowercase',
            position: 'relative',
            lineHeight: '1.4em',
            fontSize: '1.313em',
            '&.active': {
                fontWeight: 'bold',
                '&::after': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    height: '0.313em',
                    background: t.palette.primary.main,
                    left: 0,
                    right: 0,
                    bottom: '-0.49em',
                    boxShadow: t.palette.primary.mainShadow,
                    cursor: 'default',
                },
            },
        },
    },
});

export { rangeSelectStyles };
