import { shadowedBox } from '../../../mixins';

const styles = (theme) => {
    return {
        box: {
            ...shadowedBox(theme),
            maxHeight: '330px',
        },
        searchField: {
            position: 'relative',
            marginBottom: '15px',
            paddingLeft: '5px',
            width: '85%',

            '& input': {
                padding: '10px 0',
                width: '100%',
                outline: 'none',
                border: 'none',
                borderBottom: '1px solid #7c7c7e',

                '&::placeholder': {
                    color: theme.palette.primary.main,
                },
            },
        },
        searchIconContainer: {
            position: 'absolute',
            right: '-30px',
            top: '9px',
            width: '20px',
            height: '20px',
            borderRadius: '12px',
            backgroundColor: theme.palette.primary.main,
        },
        searchIcon: {
            margin: '3px',
            width: '14px',
            height: '14px',
        },
        listUl: {
            position: 'relative',
            overflow: 'auto',
            height: '260px',
        },
        listUIGroupped: {
            margin: '15px 0px 7px 5px',
            '& strong': {
                color: theme.palette.primary.main,
                fontSize: '20px',
            },
            '& div': {
                cursor: 'pointer',
                marginLeft: '-5px',
                padding: '10px 0 0 5px',

                '&:hover': {
                    backgroundColor: '#f5f5f5',
                },
            },
        },
    };
};

export default styles;
