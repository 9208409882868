import {setToken} from "../../api/mainInstance";

let user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")) : null;

let token;
if (localStorage.getItem("token")) {
    token = localStorage.getItem("token");
    setToken(token);
}

export const initialState = {
    user: user,
    token: token,
    loading: false,
    errorMessage: null
};

export const AuthReducer = (initialState, action) => {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...initialState,
                loading: true,
                errorMessage: null
            };
        case "LOGIN_EMAIL_SUCCESS":
            return {
                ...initialState,
                loading: false
            };
        case "LOGIN_SUCCESS":
            return {
                ...initialState,
                user: action.payload,
                token: action.token,
                loading: false
            };
        case "LOGOUT":
            return {
                ...initialState,
                user: null,
                token: ''
            };

        case "LOGIN_ERROR":
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};