import { useLocation } from 'react-router-dom';

const useBuildUrlWithAdditionalSearchParameters = (params) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([key, value]) =>
        searchParams.set(key, value),
    );
    return `${location.pathname}?${searchParams.toString()}`;
};

export { useBuildUrlWithAdditionalSearchParameters };
