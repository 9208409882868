import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AdminPageStyles from './AdminPage.styles';
import { makeStyles } from '@material-ui/core/styles';
import { SideMenu } from '../../components/SideMenu/SideMenu';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PeopleIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import { PhysicianUsers } from './PhysicianUsers/PhysicianUsers';
import { TestUsers } from './TestUsers/TestUsers';
import { Patients } from './Patients/Patients';
import { Physicians } from './Physicians/Physicians';
import { Spiros } from './Spiros/Spiros';
import { Kolibries } from './Kolibries/Kolibries';

const useStyles = makeStyles(AdminPageStyles);

const AdminPage = () => {
    const styles = useStyles();
    const { path } = useRouteMatch();
    const { t } = useTranslation();

    const sideMenuItems = [
        {
            text: t('adminPage.physicians'),
            icon: <PeopleOutlineIcon />,
            path: '/admin/physicians',
        },
        {
            text: t('adminPage.patients'),
            icon: <PeopleIcon />,
            path: '/admin/patients',
        },
        {
            text: t('common.whiteLists'),
            icon: <ListIcon />,
            children: [
                {
                    text: t('adminPage.testUsers'),
                    path: '/admin/white-lists/test-users',
                },
                {
                    text: t('adminPage.physicians'),
                    path: '/admin/white-lists/physicians-users',
                },
                {
                    text: t('adminPage.spiroSerials'),
                    path: '/admin/white-lists/spiros',
                },
                {
                    text: t('adminPage.kolibriSerials'),
                    path: '/admin/white-lists/kolibries',
                },
            ],
        },
    ];

    return (
        <div className={styles.admin}>
            <SideMenu items={sideMenuItems} />
            <main className={styles.admin__content}>
                <Switch>
                    <Route path={`${path}/physicians`}>
                        <Physicians />
                    </Route>
                </Switch>
                <Switch>
                    <Route path={`${path}/patients`}>
                        <Patients />
                    </Route>
                </Switch>
                <Switch>
                    <Route path={`${path}/white-lists/test-users`}>
                        <TestUsers />
                    </Route>
                </Switch>
                <Switch>
                    <Route path={`${path}/white-lists/physicians-users`}>
                        <PhysicianUsers />
                    </Route>
                </Switch>
                <Switch>
                    <Route path={`${path}/white-lists/spiros`}>
                        <Spiros />
                    </Route>
                </Switch>
                <Switch>
                    <Route path={`${path}/white-lists/kolibries`}>
                        <Kolibries />
                    </Route>
                </Switch>
            </main>
        </div>
    );
};

export { AdminPage };
