export function* referenceLines({ dataMap, dateStart, dateEnd }) {
    const data = [...dataMap.entries()]
        .filter(
            ([date]) =>
                dateStart.isSameOrBefore(date, 'day') &&
                dateEnd.isSameOrAfter(date, 'day'),
        )
        .map(([, value]) => value);

    const minData = data.length
        ? Math.min(100, Math.floor(Math.min(...data)))
        : 0;
    const maxData = data.length
        ? Math.max(100, Math.ceil(Math.max(...data)))
        : 100;

    const step = Math.ceil(Math.ceil((maxData - minData) / 4) / 5) * 5;

    let i = 100 - Math.floor((100 - minData) / step) * step;
    while (i <= maxData) {
        yield i;
        i += step;
    }
}
