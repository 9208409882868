const styles = (theme) => ({
    box: {
        backgroundColor: '#ff0000',
    },
    container: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    formContainer: {
        height: '180px',
    },
    loginForm: {
        marginBottom: '20px',
    },
    loginFormItem: {},
    label: {
        color: theme.palette.primary.main,
        fontSize: '18px',
    },
    textBtn: {
        marginTop: '15px',
        display: 'block',
        width: '100%',
        border: 'none',
        backgroundColor: 'transparent',
        color: theme.palette.primary.darkText,
        textAlign: 'center',
        outline: 'none',
    },
    error: {
        height: '20px',
        color: theme.palette.primary.error,
    },
});

export default styles;
