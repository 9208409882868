import httpClient from './mainInstance';

export const getPatientList = (params) =>
    httpClient.get('/physician/patients', {
        params: { pageSize: 20, ...params },
    });

export const getPatientSchedule = (patientId) =>
    httpClient.get(`/physician/patients/${patientId}/treatments`).then(
        (r) => r.data,
        (e) => {
            throw e?.response?.data?.message
                ? new Error(e.response.data.message)
                : e;
        },
    );

export const getPatientSpiroMeasurements = ({ patientId, from, to }) =>
    getPatientMeasurements('spiro', {
        patientId: patientId,
        from: from,
        to: to,
    });

export const getPatientDaySpiroMeasurements = ({ patientId, date }) =>
    getPatientMeasurements('spiro', {
        patientId: patientId,
        from: date,
        to: date,
        allResults: true,
    });

export const getPatientKolibriMeasurements = ({ patientId, from, to }) =>
    getPatientMeasurements('kolibri', {
        patientId: patientId,
        from: from,
        to: to,
    });

export const getPatientDayKolibriMeasurements = ({ patientId, date }) =>
    getPatientMeasurements('kolibri', {
        patientId: patientId,
        from: date,
        to: date,
        allResults: true,
    });

export const getPatientKolibriAdherenceMeasurements = ({
    patientId,
    from,
    to,
}) =>
    getPatientMeasurements('kolibri/adherence', {
        patientId: patientId,
        from: from,
        to: to,
    });

const getPatientMeasurements = (type, params) =>
    httpClient.get(`/physician/measurement/${type}`, { params: params }).then(
        (r) => r.data,
        (e) => {
            throw e?.response?.data?.message
                ? new Error(e.response.data.message)
                : e;
        },
    );
