const LANG = {
    de: { code: 'DE', name: 'german', i18nCode: 'de' },
    en: { code: 'EN', name: 'english', i18nCode: 'en' },
};

const findLang = (key) => {
    return (key && LANG[key]) || LANG.de;
};

export { LANG, findLang };
