import moment from 'moment';
import {
    dateFormat,
    findFirstNextData,
    findFirstPreviousData,
    findFirstSameOfNextData,
    xFromDate,
} from './buildSpiroChartDataUtils';

export function* absent({ dataMap, dateStart, dateEnd }) {
    if (
        !findFirstSameOfNextData({ dataMap, date: moment(dateStart), dateEnd })
    ) {
        return;
    }

    let state = new LineInit({
        dataMap,
        dateStart,
        dateEnd,
        date: moment(dateStart),
    });
    while (state) {
        state = state.next();
        if (state instanceof LineEnd) {
            yield state.valueOf();
        }
    }
}

function LineInit(params) {
    const { dataMap, dateEnd, date } = params;

    while (
        dataMap.has(dateFormat(date)) &&
        date.isSameOrBefore(dateEnd, 'day') &&
        date.isBefore(moment(), 'day')
    ) {
        date.add(1, 'days');
    }

    this.next = () => {
        if (
            date.isAfter(dateEnd, 'day') ||
            date.isSameOrAfter(moment(), 'day')
        ) {
            return null;
        }
        return new LineStart(params);
    };
}

function LineStart(params) {
    const { dataMap, dateStart, dateEnd, date } = params;

    const prev = findFirstPreviousData({
        dataMap,
        dateStart,
        date,
    });
    const next = findFirstNextData({ dataMap, date, dateEnd });

    params.line = [
        {
            x: xFromDate({ dateStart, date }),
            y:
                prev && next
                    ? (next.y - prev.y) /
                          ((prev.distance + next.distance) * 2) +
                      prev.y
                    : prev
                    ? prev.y
                    : next.y,
        },
    ];

    while (
        !dataMap.has(dateFormat(date)) &&
        date.isSameOrBefore(dateEnd, 'day') &&
        date.isBefore(moment(), 'day')
    ) {
        date.add(1, 'days');
    }

    this.next = () => new LineEnd(params);
}

function LineEnd(params) {
    const { dataMap, dateStart, dateEnd, date, line } = params;

    const prev = findFirstPreviousData({
        dataMap,
        dateStart,
        date,
    });
    const next = findFirstSameOfNextData({ dataMap, date, dateEnd });

    line.push({
        x: xFromDate({ dateStart, date }),
        y:
            prev && next
                ? ((prev.y - next.y) / ((prev.distance + next.distance) * 2)) *
                      (next.distance * 2 + 1) +
                  next.y
                : prev
                ? prev.y
                : next.y,
    });

    this.next = () => new LineInit({ ...params, line: undefined });
    this.valueOf = () => line;
}
