import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { CurveChart } from './CurveChart';
import { spiroDailyStyles } from './SpiroDailyData.styles';
import { Iterations } from '../../../../components/Meter/Iterations/Iterations';
import { SPIRO_METER_ID } from '../SpiroMeter/model';

const useStyles = makeStyles(spiroDailyStyles());

// TODO this component needs some refactoring
// - split into separate files
// - divide logic into separate components
// - split styles together with components

const SpiroDailyData = ({ data }) => {
    const styles = useStyles();
    const [iteration, setIteration] = useState(100);

    useEffect(() => {
        if (data) {
            setIteration(100);
        }
    }, [data]);

    const items = data && data.measurements.length > 0 ? data.measurements.concat([data.best, data.best]) : (data && data.best && [data.best]);

    if (!items || !items.length) {
        return <div></div>;
    }

    const amountOfIterations = items?.length || 1;
    const currentIteration = Math.min(amountOfIterations, Number(iteration));

    return (
        <div className={styles.container}>
            <DailyTable items={items} selectedDay={items[currentIteration - 1]} />
            <Iterations
                amount={amountOfIterations}
                current={currentIteration}
                id={SPIRO_METER_ID}
                type={2}
                onSetIteration={setIteration}
            />
        </div>
    );
};

const DailyTable = ({ items, selectedDay }) => {
    const styles = useStyles();
    const parsedDate = selectedDay.date
        ? moment(selectedDay.date)
        : moment.unix(selectedDay.timestamp).utc();

    const isFlowData = items.some(item => item.FlowData && item.FlowData.flowValues && item.FlowData.flowValues.length);

    return (
        <div>
            <div className={styles.box}>
                <div className={styles.dateContainer}>
                    <span>{parsedDate.format('hh a')}</span>
                    <span>{parsedDate.format('DD MMM YYYY')}</span>
                </div>
                {selectedDay.date && !selectedDay.empty && (
                    <div>
                        <div className={styles.tableContainer}>
                            <div className={styles.tableRow}>
                                <div>FVC</div>
                                <div>
                                    {getPercValue(
                                        selectedDay.FVC,
                                        selectedDay.target.FVC,
                                    )}
                                    %
                                </div>
                                <div>{selectedDay.FVC.toFixed(2)} L</div>
                            </div>

                            <div className={styles.tableRow}>
                                <div>FEV1</div>
                                <div>
                                    {getPercValue(
                                        selectedDay.FEV1,
                                        selectedDay.target.FEV1,
                                    )}
                                    %
                                </div>
                                <div>{selectedDay.FEV1.toFixed(2)} L</div>
                            </div>

                            <div className={styles.tableRow}>
                                <div>FEV1/FVC</div>
                                <div>
                                    {getPercValue(
                                        selectedDay.FEV1FVC,
                                        selectedDay.target.FEV1FVC,
                                    )}
                                    %
                                </div>
                                <div>{selectedDay.FEV1FVC.toFixed(2)}</div>
                            </div>

                            <div className={styles.tableRow}>
                                <div>PEF</div>
                                <div>
                                    {getPercValue(
                                        selectedDay.PEF,
                                        selectedDay.target.PEF,
                                    )}
                                    %
                                </div>
                                <div>{selectedDay.PEF.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                )}

                {selectedDay.date && isFlowData && (
                    <CurveChart payloads={items} selectedPayload={selectedDay} />
                )}
            </div>
        </div>
    );
};

const getPercValue = (real, target) =>
    parseInt(((real / target) * 100).toFixed());

export { SpiroDailyData };
