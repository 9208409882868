const styles = (theme) => {
    return {
        sideMenu: {
            width: theme.constants.sideMenuWidth,
            flexShrink: 0,
        },

        sideMenu__paper: {
            width: theme.constants.sideMenuWidth,
            position: 'static',
        },

        sideMenu__content: {
            overflow: 'auto',
        },

        sideMenu__itemChild: {
            paddingLeft: theme.spacing(9),
        },

        sideMenu__item: {
            display: 'block',
            textDecoration: 'none',
            color: 'inherit',
        },

        sideMenu__itemActive: {
            color: theme.palette.primary.main,

            '& svg': {
                color: theme.palette.primary.main,
            },
        },

        sideMenu__icon: {
            minWidth: theme.spacing(5),
        },
    };
};

export default styles;
