import httpClient from './mainInstance';

const login = (email) =>
    httpClient.post(
        '/physician/login/request',
        { email },
        { skipAuthRefresh: true },
    );

const confirm = (email, code) =>
    httpClient.post(
        '/physician/login/confirm',
        { email, code },
        { skipAuthRefresh: true },
    );

const api = {
    login,
    confirm,
};

export default api;
