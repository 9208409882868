export const kolibriBarChartStyles = (theme) => ({
    host: {},
    header: {
        borderBottom: `0.067em solid ${theme.palette.primary.border}`,
        padding: '0.067em 0',
        fontSize: '1.875em',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
    },
    bar: {
        height: '6em',
        margin: '1.5em 1.888em 0 0',
        '& svg.recharts-surface': {
            overflow: 'visible',
            '& .recharts-cartesian-axis-tick': {
                fontSize: '0.75em',
                color: theme.palette.primary.chart.spiro.axisLabel,
            },
            '& .recharts-reference-line': {
                '& text': {
                    '& tspan:first-child': {
                        fontWeight: 'bold',
                    },
                    '& tspan:last-child': {
                        fill: theme.palette.primary.main,
                    },
                },
            },
            '& .recharts-label-list': {
                '& .recharts-label': {
                    fontSize: '0.875em',
                },
            },
        },
        '&.quarter': {
            '& svg.recharts-surface': {
                '& .recharts-label-list': {
                    '& .recharts-label': {
                        fontSize: '0.5em',
                    },
                },
            },
        },
        '&.year': {
            '& svg.recharts-surface': {
                '& .recharts-label-list': {
                    '& .recharts-label': {
                        display: 'none',
                    },
                },
            },
        },
    },
});
