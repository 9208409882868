import { useState } from 'react';
import { dateStartFormat } from '../../../../components/Meter/dateStartFormat';
import { useEffect } from 'react';
import { SPIRO_METER_ID } from '../SpiroMeter/model';
import { useDateParams } from '../../../../components/Meter/useDateParams';
import { getPatientSpiroMeasurements } from '../../../../api/patientApi';
import { dateFromDateStart } from '../../../../components/Meter/dateFromDateStart';
import { buildSpiroChartData } from './buildSpiroChartData';
import moment from 'moment';

function useSpiroData(patientId) {
    const { dateStart, amountOfDays } = useDateParams(SPIRO_METER_ID);
    const [data, setData] = useState(null);

    const getData = () => {
        const start = dateFromDateStart(dateStart);
        const dateEnd = moment(start).add(amountOfDays - 1, 'days');

        return getPatientSpiroMeasurements({
            patientId: patientId,
            from: dateStart,
            to: dateStartFormat(dateEnd),
        })
            .catch((e) => {
                console.error(e?.message || e);
                return [];
            })
            .then((data) => {
                setData({
                    charts: ['FEV1', 'FVC', 'PEF'].map((key) => ({
                        title: key,
                        target: averageTarget(data, key),
                        ...buildSpiroChartData({
                            dateStart: start,
                            dateEnd: dateEnd,
                            dataMap: dataMap(data, key),
                        }),
                    })),
                });
            });
    };

    useEffect(getData, [patientId, dateStart, amountOfDays]);

    return { data };
}

const dataMap = (data, valueKey) =>
    data.reduce(
        (map, dataItem) =>
            map.set(
                dataItem.date,
                (dataItem.data[valueKey] / dataItem.data.target[valueKey]) *
                    100,
            ),
        new Map(),
    );

const averageTarget = (data, valueKey) =>
    data.reduce(
        (r, dataItem) => (r + dataItem.data.target[valueKey]) / 2,
        data[0]?.data.target[valueKey] || 0,
    );

export { useSpiroData };
