const styles = (theme) => ({
    input: {
        display: 'block',
        width: '250px',
        border: 'none',
        borderBottom: '1px solid ' + theme.palette.borderGrey,
        outline: 'none',
        padding: '7px 0',
    },
});

export default styles;
