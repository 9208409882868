import axios from 'axios';
import { ENV } from '../env';

const httpClient = axios.create({
    baseURL: ENV.APP_BACKEND_URL,
	timeout: 15000,
});

let accessToken;

export const setToken = token => (accessToken = token);

httpClient.interceptors.request.use(
    config => {
        if (accessToken && !config.skipAuthRefresh) {
            config.headers.Authorization = buildAuthorization(accessToken);
        }
        return config;
    },
    error => Promise.reject(error),
);

httpClient.interceptors.response.use(
	response => response,
	error => {
	    if (error.response.status === 401) {
            localStorage.removeItem("currentUser");
            localStorage.removeItem("token");
            window.location.reload();
        } else {
            return Promise.reject(error);
        }
    },
);

const buildAuthorization = (token) => `Bearer ${token}`;

export default httpClient;