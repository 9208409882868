import {
    METER_RANGE,
    METER_RANGE_DEFAULT,
    METER_RANGE_URL_KEY,
} from './models/range';
import moment from 'moment';
import { METER_DAY_URL_KEY } from './models/day';
import { useLocation } from 'react-router-dom';
import { METER_DATE_START_URL_KEY } from './models/dateStart';
import { dateStartFormat } from './dateStartFormat';
import { dateFromDateStart } from './dateFromDateStart';
import { METER_ITERATION_URL_KEY } from './models/iteration';

/**
 * Reads date params from url
 */
const useDateParams = (id) => {
    const range = useRange(id);
    const dateStart = useDateStart(id, range);
    const amountOfDays = amountOfDaysInRange({ range, dateStart });
    let iteration = useIteration(id);
    iteration = (iteration && String(Math.max(Number(iteration), 1))) || '1';

    const dateMin = dateFromDateStart(dateStart);
    const dateMax = dateFromDateStart(dateStart).add(amountOfDays - 1, 'days');
    let day = useDay(id);
    if (day) {
        if (dateFromDateStart(day).isAfter(dateMax)) {
            day = dateStartFormat(dateMax);
        }
        if (dateFromDateStart(day).isBefore(dateMin)) {
            day = dateStartFormat(dateMin);
        }
    }

    return { dateStart, range, day, amountOfDays, iteration };
};

const useDay = (id) => {
    return new URLSearchParams(useLocation().search).get(
        [METER_DAY_URL_KEY, id].join('_'),
    );
};

const useDateStart = (id, range) => {
    return (
        new URLSearchParams(useLocation().search).get(
            [METER_DATE_START_URL_KEY, id].join('_'),
        ) ||
        (() => {
            switch (range) {
                case METER_RANGE.QUARTER:
                    return dateStartFormat(moment().startOf('quarter'));
                case METER_RANGE.YEAR:
                    return dateStartFormat(moment().startOf('year'));
                case METER_RANGE.MONTH:
                default:
                    return dateStartFormat(moment().startOf('month'));
            }
        })()
    );
};

const useRange = (id) => {
    return (
        new URLSearchParams(useLocation().search).get(
            [METER_RANGE_URL_KEY, id].join('_'),
        ) || METER_RANGE_DEFAULT
    );
};

const useIteration = (id) => {
    return new URLSearchParams(useLocation().search).get(
        [METER_ITERATION_URL_KEY, id].join('_'),
    );
};

const amountOfDaysInRange = ({ range, dateStart }) => {
    switch (range) {
        case METER_RANGE.QUARTER:
            return [
                Number(dateFromDateStart(dateStart).endOf('month').format('D')),
                Number(
                    dateFromDateStart(dateStart)
                        .add(1, 'months')
                        .endOf('month')
                        .format('D'),
                ),
                Number(
                    dateFromDateStart(dateStart)
                        .add(2, 'months')
                        .endOf('month')
                        .format('D'),
                ),
            ].reduce((sum, add) => sum + add, 0);
        case METER_RANGE.YEAR:
            return Number(
                dateFromDateStart(dateStart).endOf('year').format('DDD'),
            );
        case METER_RANGE.MONTH:
        default:
            return Number(
                dateFromDateStart(dateStart).endOf('month').format('D'),
            );
    }
};

export { useDateParams };
