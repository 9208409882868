import {
    dateFormat,
    dateSameOrNextGenerator,
} from '../Spiro/useSpiroData/buildSpiroChartDataUtils';
import moment from 'moment';

export function buildKolibriAdherenceData({ dataMap, dateStart, dateEnd }) {
    return [
        ...dateSameOrNextGenerator({ date: dateStart, dateEnd: dateEnd }),
    ].map((date, index) => {
        const item = dataMap.get(dateFormat(date));
        return {
            x: index,
            ...calculateItem(item),
        };
    });
}

function calculateItem(item) {
    if (!item) {
        return { y: 0, label: '' };
    }
    // don't show future values (temporary decision?)
    if (moment(item.date).isAfter(moment())) {
        return {
            yf: item.scheduled ? 100 : 1,
            labelF: item.scheduled,
        };
    }
    if (item.done > item.scheduled) {
        return { y: 105, label: `${item.done}/${item.scheduled}` };
    }
    if (!item.scheduled) {
        return { y: 1, label: `${item.done}/${item.scheduled}` };
    }
    return {
        y: Math.max(1, (item.done / item.scheduled) * 100),
        label: `${item.done}/${item.scheduled}`,
    };
}
