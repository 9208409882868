const selectionStyles = (theme) => ({
    host: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        position: 'relative',
        '&:focus': {
            outline: '0 none',
        },
        '&.active': {
            background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}) no-repeat center/0.125em 100%`,
            '&::before': {
                position: 'absolute',
                width: '2.5em',
                left: '-1.25em',
                marginLeft: '50%',
                content: '""',
                display: 'block',
                height: '0.375em',
                background: theme.palette.primary.main,
                boxShadow: theme.palette.primary.mainShadow,
                cursor: 'default',
                top: 0,
            },
        },

        '&.active, &:hover, &:focus': {
            '& $label': {
                display: 'none',
            },
            '& $labelHover': {
                display: 'flex',
            },
        },

        '&.month': {
            '& $label': {
                fontSize: '0.75em',
                top: '-2em',
                height: '2em',
            },
        },
        '&.quarter': {
            '& $label': {
                // use px because em does not set it so small
                fontSize: '7px',
                top: '-3em',
                height: '3em',
            },
        },
        '&.year': {
            '& $label': {
                // use px because em does not set it so small
                fontSize: '1px',
                top: '-17em',
                height: '17em',
            },
        },
    },
    label: {
        left: '-0.5em',
        width: '1em',
        marginLeft: '50%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        lineHeight: '1.2em',
    },
    labelHover: {
        left: '-0.5em',
        top: '-1.45em',
        height: '1.45em',
        width: '1em',
        marginLeft: '50%',
        position: 'absolute',
        fontSize: '1.3125em',
        fontWeight: 'bold',
        display: 'none',
        justifyContent: 'center',
    },
    monthLabel: {
        left: '40%',
        position: 'absolute',
        fontSize: '0.75em',
        fontWeight: 'bold',
        top: '-3.5em',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
    },
});

export { selectionStyles };
