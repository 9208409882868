import React from 'react';
import { BarChart as BarChartOriginal, ResponsiveContainer } from 'recharts';

export const BarChart = ({
    margin = { top: 0, right: 0, bottom: 0, left: 0 },
    children,
    ...props
}) => {
    return (
        <ResponsiveContainer>
            <BarChartOriginal margin={margin} {...props}>
                {children}
            </BarChartOriginal>
        </ResponsiveContainer>
    );
};
