import React from 'react';
import { LineChart as LineChartOriginal, ResponsiveContainer } from 'recharts';

const LineChart = ({
    margin = { top: 0, right: 0, bottom: 0, left: 0 },
    children,
    ...props
}) => {
    return (
        <ResponsiveContainer>
            <LineChartOriginal margin={margin} {...props}>
                {children}
            </LineChartOriginal>
        </ResponsiveContainer>
    );
};

export { LineChart };
