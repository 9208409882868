export const theme = {
    palette: {
        primary: {
            main: '#26a8c9',
            mainBackground: '#ffffff',
            darkText: '#4d5152',
            disabled: '#ccc',
            error: '#ff0000',
            border: '#595959',
            mainShadow: '0 1px 2px rgba(0, 0, 0, 0.27)',
            boxShadow: '0px 4px 8px 0px rgba(158,161,161,0.4)',
            chart: {
                spiro: {
                    present: '#26a8c9',
                    absent: '#e35152',
                    future: 'rgba(158, 161, 161, 0.45)',
                    axisLabel: '#595959',
                    referenceLine: 'rgba(38, 168, 201, 0.3)',
                },
            },
        },
        borderGrey: '#7c7c7e',
    },
    typography: {
        fontFamily: ['Proxima Nova Rg', 'sans-serif'].join(','),
    },
    constants: {
        sideMenuWidth: 240,
    },
};
