import React from 'react';
import { HashRouter, Switch } from 'react-router-dom';
import { StylesProvider, ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';

import routes from './config/routes.js';
import AppRoutes from './config/AppRoutes';
import {AuthProvider} from './сontexts/auth';
import {PatientProvider} from './сontexts/patient';
import {theme} from './theme';
import AppStyles from './App.styles';
import {Header} from "./components/Header/Header";

const customTheme = createMuiTheme(theme);
const useStyles = makeStyles(AppStyles);

function App() {
  const styles = useStyles();
  return (
      <ThemeProvider theme={customTheme}>
          <StylesProvider injectFirst>
              <AuthProvider>
                  <PatientProvider>
                      <HashRouter>
                          <div className={styles.app}>
                              <Header />
                              <div className={styles.content}>
                                  <Switch>
                                      {routes.map((route) => (
                                          <AppRoutes
                                              key={route.path}
                                              path={route.path}
                                              component={route.component}
                                              needAuth={route.needAuth}
                                              needAdminPerm={route.needAdminPerm}
                                          />
                                      ))}
                                  </Switch>
                              </div>
                          </div>
                      </HashRouter>
                  </PatientProvider>
              </AuthProvider>
          </StylesProvider>
      </ThemeProvider>
  );
}

export default App;
