import { dateFromDateStart } from '../dateFromDateStart';
import { dateStartFormat } from '../dateStartFormat';
import { useDateParams } from '../useDateParams';
import { useDateParamsUrl } from '../useDateParamsUrl';

const useRangeUrl = (id, range) => {
    const { dateStart, day } = useDateParams(id);

    const start = dateFromDateStart(day ? day : dateStart);

    return useDateParamsUrl(id, {
        range,
        dateStart: dateStartFormat(start.startOf(range)),
    });
};

export { useRangeUrl };
