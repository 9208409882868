const styles = (theme) => {
    return {
        physicians: {
            width: 1000,
        },

        physicians__icon: {
            display: 'block',
            color: theme.palette.text.secondary,
        },
    };
};

export default styles;
