import React from 'react';
import SubmitButtonStyles from './SubmitButton.styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(SubmitButtonStyles);

const SubmitButton = ({type, label, onClick, disabled}) => {
    const styles = useStyles();

    return (
        <button disabled={disabled} className={styles.host} type={type || 'button'} onClick={onClick}>{label}</button>
    );
};

export { SubmitButton };
