import { theme } from '../../../theme';

const daySelectStyles = (t = theme) => ({
    host: {
        borderBottom: `0.125em solid ${t.palette.primary.border}`,
    },
    dates: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `0.125em solid ${t.palette.primary.border}`,
        '& .center': {
            padding: '1em 0 0.875em',
            flexGrow: 1,
            flexBasis: 0,
            '& .days': {
                display: 'flex',
                height: '1.563em',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& > *': {
                    flexGrow: 1,
                    flexBasis: 0,
                    textAlign: 'center',
                },
            },
        },
        '& > *:first-child, & > *:last-child': {
            width: '0.938em',
            height: '3em',
        },
        '& > *:first-child': {
            padding: '0 0.7em 0 0.125em',
        },
        '& > *:last-child': {
            padding: '0 0.125em 0 0.7em',
        },
    },
    charts: {
        margin: '0 1.888em',
        position: 'relative',
        '& > *:last-child': {
            position: 'absolute',
            top: '-0.25em',
            left: 0,
            bottom: 0,
            right: 0,
        },
    },
    selection: {
        display: 'flex',
        '& > *': {
            flex: '1 0 0',
        },
    },
});

export { daySelectStyles };
