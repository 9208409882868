import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import { KOLIBRI_METER_ID } from './KolibriMeter/model';
import {
    getPatientKolibriAdherenceMeasurements,
    getPatientKolibriMeasurements,
} from '../../../api/patientApi';
import { buildSpiroChartData } from '../Spiro/useSpiroData/buildSpiroChartData';
import { useDateParams } from '../../../components/Meter/useDateParams';
import { dateFromDateStart } from '../../../components/Meter/dateFromDateStart';
import { dateStartFormat } from '../../../components/Meter/dateStartFormat';
import { buildKolibriAdherenceData } from './buildKolibriAdherenceData';

/**
 * It reuses spiro utils for now (temporary?)
 */

export function useKolibriData(patientId) {
    const { dateStart, amountOfDays } = useDateParams(KOLIBRI_METER_ID);
    const [data, setData] = useState(null);

    const getData = () => {
        const start = dateFromDateStart(dateStart);
        const dateEnd = moment(start).add(amountOfDays - 1, 'days');

        return Promise.all([
            getPatientKolibriMeasurements({
                patientId: patientId,
                from: dateStart,
                to: dateStartFormat(dateEnd),
            }),
            getPatientKolibriAdherenceMeasurements({
                patientId: patientId,
                from: dateStart,
                to: dateStartFormat(dateEnd),
            }),
        ])
            .catch((e) => {
                console.error(e?.message || e);
                return [[], []];
            })
            .then(([data, dataAdherence]) => {
                setData({
                    charts: [
                        'inhalationQuality',
                        'takeLongBreaths',
                        'breatheSlowly',
                    ].map((key) => ({
                        title: key,
                        ...buildSpiroChartData({
                            dateStart: start,
                            dateEnd: dateEnd,
                            dataMap: dataMap(data, key),
                        }),
                    })),
                    adherence: {
                        series: buildKolibriAdherenceData({
                            dateStart: start,
                            dateEnd: dateEnd,
                            dataMap: dataAdherenceMap(dataAdherence),
                        }),
                        referenceLines: [20, 40, 60, 80, 100],
                    },
                });
            });
    };

    useEffect(getData, [patientId, dateStart, amountOfDays]);

    return { data };
}

const dataMap = (data, valueKey) =>
    data.reduce(
        (map, dataItem) => map.set(dataItem.date, dataItem.avg[valueKey]),
        new Map(),
    );

const dataAdherenceMap = (data) =>
    data.reduce((map, dataItem) => map.set(dataItem.date, dataItem), new Map());
