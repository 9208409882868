const styles = (theme) => {
    return {
        physicianUsers: {
            width: 600,
        },

        physicianUsers__icon: {
            color: theme.palette.text.secondary,
        },
    };
};

export default styles;
