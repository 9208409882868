import React, {useState} from 'react';
import componentStyles from './SelectPatientList.styles';
import searchIcon from '../../../assets/images/search.png';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { getPatientList } from "../../../api/patientApi";
import InfiniteScroll from 'react-infinite-scroller';

const useStyles = makeStyles(componentStyles);

let searchValueTimer;
let searchQuery;
let usingQuery;

const SelectPatientList = ({onSelectedPatient}) => {
    const styles = useStyles();
    const { t } = useTranslation();

    const [state, setState] = useState({
        patients: [],
        nextPage: 0,
        q: '',
        loading: false,
    });

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedPatient, setSelectedPatient] = useState({group: null, row: null});

    const handleChange = e => {
        searchQuery = e.target.value;
        setSearchTerm(e.target.value);
        if (searchValueTimer) {
            clearTimeout(searchValueTimer);
            searchValueTimer = null;
        }

        searchValueTimer = setTimeout(() => {
            usingQuery = true;
            getPatients(null, true);
        }, 250);
    };

    const group = state.patients
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
        .reduce((r, e) => {
            const key = e.firstName[0];
            if(!r[key]) r[key] = [];
            r[key].push(e);
            return r;
        }, {});

    const getPatients = (e, reset) => {
        state.nextPage = reset ? 0 : (usingQuery ? 1 : state.nextPage);
        state.loading = true;
        const request = getPatientList({
            pageSize: 20,
            page: state.nextPage,
            q: searchQuery,
        });

        request
            .then((result) => {
                setState({
                    count: result.data.count,
                    patients: reset ? result.data.result : [...state.patients, ...result.data.result],
                    nextPage: state.nextPage + 1,
                    loading: false,
                });

                if (e) {
                    usingQuery = false;
                }
            })
            .catch((e) => {
                    console.log(e);
                }
            );
    };

    const selectPatient = (group, row, patient) => {
        setSelectedPatient({group, row});
        onSelectedPatient(patient);
    };

    return (
        <div className={styles.box}>
            <div className={styles.searchField}>
                <input
                    type='text'
                    placeholder={t('homePage.patientList.search')}
                    value={searchTerm}
                    onChange={handleChange}
                />
                <div className={styles.searchIconContainer}>
                    <img src={searchIcon} className={styles.searchIcon} alt="" />
                </div>
            </div>
            <div className={styles.listUl}>
                <InfiniteScroll
                    loadMore={getPatients}
                    threshold={100}
                    useWindow={false}
                    hasMore={!state.loading && (state.count === undefined || state.patients.length < state.count)}
                >
                {Object.entries(group)
                    .map(([key, value], i) => (
                        <div className={styles.listUIGroupped} key={i}>
                            <strong>{key}</strong>
                            {value.map((item, j) =>
                                <div style={{backgroundColor:
                                        (selectedPatient.group === i && selectedPatient.row === j) && '#eee'}}
                                     onClick={() => selectPatient(i, j, item)}
                                     key={j}>{item.firstName} {item.lastName}</div>)}
                        </div>
                    ))
                }
                </InfiniteScroll>
            </div>
        </div>
    );
};

export {SelectPatientList};
