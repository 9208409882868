import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/images/pulmotreeLogoMedicalCmyk@3x.png';
import logoutIcon from '../../assets/images/logout.png';
import { headerStyles } from './Header.styles';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { logout, useAuthDispatch, useAuthState } from '../../сontexts/auth';

const useStyles = makeStyles(headerStyles);

const LangItem = ({ lng }) => {
    const { i18n } = useTranslation();
    const styles = useStyles();

    return (
        <button
            className={styles.langButton}
            style={{ color: i18n.language === lng && '#4d5152' }}
            onClick={() => i18n.changeLanguage(lng)}
        >
            {lng}
        </button>
    );
};

export const Header = () => {
    const styles = useStyles();
    const history = useHistory();
    const dispatch = useAuthDispatch();
    const userDetails = useAuthState();

    const handleLogout = async () => {
        await logout(dispatch); //call the logout action
        history.push('/login'); //navigate to logout page on logout
    };

    return (
        <div className={styles.host}>
            <Link to="/">
                <img src={logo} className={styles.logo} alt="" />
            </Link>
            <div className={styles.rightSide}>
                <div className={styles.profile}>{userDetails?.user?.email}</div>
                <div className={styles.buttons}>
                    <LangItem lng="de" />
                    <LangItem lng="en" />
                    {userDetails.token && (
                        <button
                            className={styles.logout}
                            onClick={handleLogout}
                        >
                            <img
                                className={styles.logoutIcon}
                                src={logoutIcon}
                                alt=""
                            />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
