import { theme } from '../../../../theme';

const spiroMeterStyles = (t = theme) => ({
    host: {},
    chart: {
        height: '6.875em',
        padding: '1.25em 0',
        '& svg.recharts-surface': {
            overflow: 'visible',
            '& .recharts-cartesian-axis-tick': {
                fontSize: '0.75em',
                color: t.palette.primary.chart.spiro.axisLabel,
            },
            '& .recharts-reference-line': {
                '& text': {
                    '& tspan:first-child': {
                        fontWeight: 'bold',
                    },
                    '& tspan:last-child': {
                        fill: t.palette.primary.main,
                    },
                },
            },
        },
        marginLeft: '-1.888em',
    },
});

export { spiroMeterStyles };
