import { theme } from '../../theme';

const meterStyles = (t = theme) => ({
    host: {},
    header: {
        display: 'flex',
        borderBottom: `0.125em solid ${t.palette.primary.border}`,
        padding: '0.125em 0',
        '& > *': {
            flexBasis: 0,
        },
        '& > *:first-child, & > *:last-child': {
            flexGrow: 1,
            fontSize: '1.875em',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: t.palette.primary.main,
        },
        '& > *:nth-child(2)': {
            flexGrow: 1,
            margin: '0 1em',
            maxWidth: '25em',
        },
    },
});

export { meterStyles };
