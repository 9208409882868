import React from 'react';
import { Bar, LabelList, ReferenceLine, XAxis, YAxis } from 'recharts';
import { theme } from '../../../../theme';
import { BarChart } from '../../../../components/Chart/BarChart';
import { kolibriBarChartStyles } from './KolibriBarChart.styles';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDateParams } from '../../../../components/Meter/useDateParams';
import { KOLIBRI_METER_ID } from './model';

const useStyles = makeStyles(kolibriBarChartStyles);

export const KolibriBarChart = ({ data: { series, referenceLines } }) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const { range } = useDateParams(KOLIBRI_METER_ID);

    const FutureLine = ({ x, y, width, fill, labelF }) => {
        if (labelF === undefined) {
            return null;
        }
        return (
            <path d={`M ${x} ${y} h ${width} v 2 h -${width}`} fill={fill} />
        );
    };

    return (
        <div className={styles.host}>
            <div className={styles.header}>
                {t('homePage.kolibri.adherence')}
            </div>
            <div className={`${styles.bar} ${range}`}>
                <BarChart data={series}>
                    <XAxis dataKey="x" domain={[0, 'dataMax']} hide={true} />
                    <YAxis
                        dataKey="y"
                        type="number"
                        domain={[0, 105]}
                        axisLine={false}
                        tickLine={false}
                        unit="%"
                        width={30.2}
                        ticks={referenceLines}
                        interval={0}
                    />

                    <Bar
                        maxBarSize={20}
                        dataKey="y"
                        fill={theme.palette.primary.main}
                        stackId="a"
                    >
                        <LabelList
                            dataKey="label"
                            position="top"
                            fill={theme.palette.primary.main}
                        />
                    </Bar>

                    <Bar
                        maxBarSize={20}
                        dataKey="yf"
                        fill={theme.palette.primary.chart.spiro.future}
                        stackId="a"
                        shape={<FutureLine />}
                    >
                        <LabelList
                            dataKey="labelF"
                            position="top"
                            fill={theme.palette.primary.chart.spiro.future}
                        />
                    </Bar>

                    {referenceLines.map((line, i) => (
                        <ReferenceLine
                            key={`referenceLine${i}`}
                            y={line}
                            stroke={
                                theme.palette.primary.chart.spiro.referenceLine
                            }
                            strokeWidth={1}
                            strokeDasharray={line !== 100 ? '9 5' : 'none'}
                        />
                    ))}
                </BarChart>
            </div>
        </div>
    );
};
