import moment from 'moment';

export function* datePreviousGenerator({ date, dateStart }) {
    const d = moment(date);
    while (true) {
        d.subtract(1, 'days');
        if (d.isBefore(dateStart, 'day')) {
            return;
        }
        yield moment(d);
    }
}

export function* dateSameOrNextGenerator({ date, dateEnd }) {
    const d = moment(date);
    while (true) {
        if (d.isAfter(dateEnd, 'day')) {
            return;
        }
        yield moment(d);
        d.add(1, 'days');
    }
}

export function* dateNextGenerator({ date, dateEnd }) {
    const d = moment(date);
    while (true) {
        d.add(1, 'days');
        if (d.isAfter(dateEnd, 'day')) {
            return;
        }
        yield moment(d);
    }
}

export const findFirstPreviousData = ({ dataMap, date, dateStart }) => {
    let distance = 1;
    for (const d of datePreviousGenerator({ date, dateStart })) {
        const key = dateFormat(d);
        if (dataMap.has(key)) {
            return {
                y: dataMap.get(key),
                distance: distance,
            };
        }
        distance++;
    }
    return undefined;
};

export const findFirstNextData = ({ dataMap, date, dateEnd }) => {
    let distance = 1;
    for (const d of dateNextGenerator({ date, dateEnd })) {
        const key = dateFormat(d);
        if (dataMap.has(key)) {
            return {
                y: dataMap.get(key),
                distance: distance,
            };
        }
        distance++;
    }
    return undefined;
};

export const findFirstSameOfNextData = ({ dataMap, date, dateEnd }) => {
    let distance = 0;
    for (const d of dateSameOrNextGenerator({ date, dateEnd })) {
        const key = dateFormat(d);
        if (dataMap.has(key)) {
            return {
                y: dataMap.get(key),
                distance: distance,
            };
        }
        distance++;
    }
    return undefined;
};

export const dateFormat = (date) => date.format('YYYY-MM-DD');

export const xFromDate = ({ dateStart, date }) =>
    date.diff(dateStart, 'days') * 2;
