import React, { useState } from 'react';
import LoginPageStyles from './LoginPage.styles';
import { makeStyles } from '@material-ui/core/styles';
import {loginEmail, loginConfirm, useAuthState, useAuthDispatch} from '../../сontexts/auth';
import { useTranslation } from 'react-i18next';
import {FormField} from "../../components/FormField/FormField";
import {SubmitButton} from "../../components/SubmitButton/SubmitButton";

const useStyles = makeStyles(LoginPageStyles);

const LoginPage = (props) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const [tab, setTab] = useState('EMAIL');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');

    const dispatch = useAuthDispatch();
    const { loading, errorMessage } = useAuthState();

    const handleEmail = (e) => {
        if (tab !== 'EMAIL') {
            return;
        }

        sendEmail(e);
    };

    const sendEmail = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        const success = await loginEmail(dispatch, email);
        if (!success) return;
        setTab('CODE');
    };

    const handleCode = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        const response = await loginConfirm(dispatch, email, code);
        if (!response || (response && !response.email)) return;

        const isAdmin = response.permissions.includes('admin');
        props.history.push(isAdmin ? '/admin' : '/');
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.formContainer}>
                    <p className={styles.error}>
                        {errorMessage && <span>{t('loginPage.formError.' + errorMessage)}</span>}
                    </p>
                    {tab === 'EMAIL' && (
                        <form autoComplete="off" onSubmit={handleEmail}>
                            <div className={styles.loginForm}>
                                <div className={styles.loginFormItem}>
                                    <label className={styles.label}>{t('loginPage.emailLabel')}</label>
                                    <FormField value={email} type="text" name="login" onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                            </div>
                            <SubmitButton type='submit' disabled={loading || !email} label={t('loginPage.emailFormSubmit')}/>
                        </form>
                    )}

                    {tab === 'CODE' && (
                        <div>
                            <form autoComplete="off" onSubmit={handleCode}>
                                <div className={styles.loginForm}>
                                    <div className={styles.loginFormItem}>
                                        <label className={styles.label}>{t('loginPage.codeLabel')}</label>
                                        <FormField value={code} type="text" name="code" onChange={(e) => setCode(e.target.value)}/>
                                    </div>
                                </div>
                                <SubmitButton type='submit' label={t('loginPage.codeFormSubmit')} disabled={loading || !code}/>
                            </form>
                            <button className={styles.textBtn} onClick={sendEmail}>{t('loginPage.codeFormResend')}</button>
                            <button className={styles.textBtn} onClick={() => setTab('EMAIL')}>{t('loginPage.codeFormBackToEmail')}</button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export { LoginPage };
