import { present } from './present';
import { absent } from './absent';
import { future } from './future';
import { referenceLines } from './referenceLines';

const buildSpiroChartData = ({ dataMap, dateStart, dateEnd }) => {
    /**
     * Each day has two points: start of the day and middle of the day (according to the chart)
     * End of the day is the same point as start of the next day
     * The last point on the chart is a special case - end of the last day, so the last day has three points
     */

    return {
        series: [present, absent, future].map((generator) => [
            ...generator({ dataMap, dateStart, dateEnd }),
        ]),
        referenceLines: [...referenceLines({ dataMap, dateStart, dateEnd })],
    };
};

export { buildSpiroChartData };
