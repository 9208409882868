import {setToken} from "../../api/mainInstance";
import authApi from '../../api/authApi';

export async function loginEmail(dispatch, email) {
    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        await authApi.login(email);
        dispatch({ type: 'LOGIN_EMAIL_SUCCESS' });
        return true;
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: 'invalidEmail' });
    }
}

export async function loginConfirm(dispatch, email, code) {
    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        const {data} = await authApi.confirm(email, +code);

        if (data.token) {
            const payload = {
                email: email,
                permissions: data.permissions
            };
            setToken(data.token);
            dispatch({ type: 'LOGIN_SUCCESS', payload, token: data.token });
            localStorage.setItem('currentUser', JSON.stringify(payload));
            localStorage.setItem('token', data.token);
            return payload;
        }
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: 'invalidCode' });
    }
}

export async function logout(dispatch) {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
}