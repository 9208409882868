import React from 'react';
import {
    LineChart,
    Line,
    ResponsiveContainer,
    YAxis,
    XAxis,
    CartesianGrid,
} from 'recharts';
import { spiroDailyStyles } from './SpiroDailyData.styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(spiroDailyStyles());

const CurveChart = ({ payloads, selectedPayload }) => {
    const styles = useStyles();

    const mapData = () => {
        let chartData = [];

        payloads.forEach((item) => {
            let volumeStep = 0;
            let mappedData = [];

            item.FlowData.flowValues.forEach((itemFlowValue) => {
                const flowItem = {
                    flow: itemFlowValue,
                    step: volumeStep / 1000,
                };
                volumeStep += item.FlowData.volumeStep;
                mappedData.push(flowItem);
            });

            const chart = {
                data: mappedData,
                svg: item.FEV1 === selectedPayload.FEV1 ? '#000' : '#26A8C9',
            };
            chartData.push(chart);
        });

        return chartData;
    };

    const chartData = mapData();

    return (
        <div className={styles.curveChartContainer}>
            <ResponsiveContainer height={240}>
                <LineChart
                    margin={{ top: 20, bottom: 10, left: -20, right: 15 }}
                >
                    <CartesianGrid vertical={false} />

                    <XAxis
                        dataKey={'step'}
                        allowDecimals={true}
                        type={'number'}
                        tickLine={false}
                        tick={{ fontSize: 13, fill: '#000' }}
                        label={{
                            value: 'Volume (L)',
                            fontSize: 12,
                            position: 'insideBottomRight',
                            dx: 20,
                            dy: 10,
                        }}
                    />
                    <YAxis
                        dataKey={'flow'}
                        allowDecimals={true}
                        type={'number'}
                        tickLine={false}
                        tick={{ fontSize: 13, fill: '#000' }}
                        label={{
                            value: 'Flow (L/S)',
                            fontSize: 12,
                            position: 'insideTopLeft',
                            dx: 30,
                            dy: -25,
                        }}
                    />

                    {chartData.map(chart => (
                        <Line
                            type="monotone"
                            dataKey={'flow'}
                            data={chart.data}
                            dot={false}
                            stroke={chart.svg}
                            strokeWidth={2}
                            yAxisId={0}
                        />
                    ))}

                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export { CurveChart };
