import moment from 'moment';
import {
    dateFormat,
    findFirstNextData,
    findFirstPreviousData,
    xFromDate,
} from './buildSpiroChartDataUtils';

export function* present({ dataMap, dateStart, dateEnd }) {
    let state = new LineInit({
        dataMap,
        dateStart,
        dateEnd,
        date: moment(dateStart),
    });
    while (state) {
        state = state.next();
        if (state instanceof LineEnd) {
            yield state.valueOf();
        }
    }
}

function LineInit(params) {
    const { dataMap, dateEnd, date } = params;

    while (
        !dataMap.has(dateFormat(date)) &&
        date.isSameOrBefore(dateEnd, 'day')
    ) {
        date.add(1, 'days');
    }

    this.next = () => {
        if (date.isAfter(dateEnd, 'day')) {
            return null;
        }
        return new LineStart(params);
    };
}

function LineStart(params) {
    const { dataMap, dateStart, date } = params;

    const prev = findFirstPreviousData({
        dataMap,
        dateStart,
        date,
    });
    const y = dataMap.get(dateFormat(date));
    params.line = [
        {
            x: xFromDate({ dateStart, date }),
            y: prev ? (prev.y - y) / (prev.distance * 2) + y : y,
        },
    ];

    this.next = () => new LineMiddle(params);
}

function LineMiddle(params) {
    const { dataMap, dateStart, dateEnd, date, line } = params;

    line.push({
        x: xFromDate({ dateStart, date }) + 1,
        y: dataMap.get(dateFormat(date)),
    });

    date.add(1, 'days');

    this.next = () => {
        if (date.isAfter(dateEnd, 'day') || !dataMap.has(dateFormat(date))) {
            return new LineEnd(params);
        }
        return new LineMiddle(params);
    };
}

function LineEnd(params) {
    const { dataMap, dateStart, dateEnd, date, line } = params;

    const next = findFirstNextData({ dataMap, date, dateEnd });
    const y = line[line.length - 1].y;
    line.push({
        x: xFromDate({ dateStart, date }),
        y: next ? (next.y - y) / ((next.distance + 1) * 2) + y : y,
    });

    this.next = () => new LineInit({ ...params, line: undefined });
    this.valueOf = () => line;
}
