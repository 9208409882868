import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TestUsersStyles from './TestUsers.styles';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import AdminApi from '../../../api/AdminApi';
import { ArrowDownward } from '@material-ui/icons';

const useStyles = makeStyles(TestUsersStyles);
const TEST_USERS_LIST_TYPE = 'testUsers';

const TestUsers = () => {
    const styles = useStyles();
    const { t } = useTranslation();

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        Check: forwardRef((props, ref) => (
            <Check
                {...props}
                classes={{ root: styles.testUsers__icon }}
                ref={ref}
            />
        )),
        Clear: forwardRef((props, ref) => (
            <Clear
                {...props}
                classes={{ root: styles.testUsers__icon }}
                ref={ref}
            />
        )),
        Delete: forwardRef((props, ref) => (
            <DeleteOutline
                {...props}
                classes={{ root: styles.testUsers__icon }}
                ref={ref}
            />
        )),
    };

    const columns = [{ title: t('common.email'), field: 'value' }];

    const [data, setData] = useState([]);

    useEffect(() => {
        loadTableData();
    }, []);

    const loadTableData = () => {
        return AdminApi.getWhiteList(TEST_USERS_LIST_TYPE)
            .then((result) => {
                setData(result.data.result);
            })
            .catch((e) => console.log(e));
    };

    const onRowAdd = (data) => {
        return AdminApi.saveToWhiteList(data.value, TEST_USERS_LIST_TYPE)
            .then(loadTableData)
            .catch((e) => console.log(e));
    };

    const onRowDelete = (data) => {
        return AdminApi.deleteFromWhiteList(data.value, TEST_USERS_LIST_TYPE)
            .then(loadTableData)
            .catch((e) => console.log(e));
    };

    return (
        <section className={styles.testUsers}>
            <MaterialTable
                options={{
                    search: false,
                    paging: false,
                    actionsColumnIndex: -1,
                }}
                title={t('adminPage.testUsers')}
                icons={tableIcons}
                columns={columns}
                data={data}
                editable={{
                    onRowAdd,
                    onRowDelete,
                }}
            />
        </section>
    );
};

export { TestUsers };
