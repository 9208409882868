import { shadowedBox, label } from '../../../mixins';

export const patientScheduleStyles = (theme) => ({
    container: {
        flexGrow: 1,
    },
    box: {
        ...shadowedBox(theme),
    },
    label: {
        ...label(theme),
        marginBottom: '20px',
    },
    boxWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    treatmentContainer: {
        '&:nth-child(2)': {
            marginTop: '15px',
        },
    },
    treatmentName: {
        margin: 0,
        fontSize: 14,
        textTransform: 'uppercase',
        color: '#26a8c9',
        fontWeight: 'bold',
    },
    scheduleListContainer: {
        paddingLeft: 10,
    },
    scheduleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '1px solid #26a8c9',
    },
    time: {
        margin: 0,
        fontSize: 13,
        fontWeight: 'bold',
        color: '#4d5152',
        marginRight: '25px',
        lineHeight: '22px',
    },
    days: {
        margin: 0,
        fontSize: 13,
        color: '#4d5152',
        lineHeight: '22px',
        marginRight: '5px',
    },
    noSchedule: {
        fontSize: 13,
        color: '#4d5152',
    },
});
