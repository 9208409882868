import { makeStyles } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { METER_RANGE } from '../models/range';
import { useRangeUrl } from './useRangeUrl';
import { rangeSelectStyles } from './RangeSelect.styles';
import { useTranslation } from 'react-i18next';
import { useDateParams } from '../useDateParams';

const useStyles = makeStyles(rangeSelectStyles());

const RangeSelect = ({ id }) => {
    const styles = useStyles();
    const { range } = useDateParams(id);
    const { t } = useTranslation();

    const isActive = (rangeValue) => () => range === rangeValue;

    return (
        <div className={styles.host}>
            <NavLink
                to={useRangeUrl(id, METER_RANGE.YEAR)}
                isActive={isActive(METER_RANGE.YEAR)}
            >
                {t('common.year')}
            </NavLink>
            <NavLink
                to={useRangeUrl(id, METER_RANGE.QUARTER)}
                isActive={isActive(METER_RANGE.QUARTER)}
            >
                {t('common.quarter')}
            </NavLink>
            <NavLink
                to={useRangeUrl(id, METER_RANGE.MONTH)}
                isActive={isActive(METER_RANGE.MONTH)}
            >
                {t('common.month')}
            </NavLink>
        </div>
    );
};

export { RangeSelect };
