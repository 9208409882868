import { useDateParams } from '../../../components/Meter/useDateParams';
import { dateFromDateStart } from '../../../components/Meter/dateFromDateStart';
import { useEffect, useState } from 'react';
import { getPatientDayKolibriMeasurements } from '../../../api/patientApi';
import { KOLIBRI_METER_ID } from './KolibriMeter/model';
import { dateFormat } from '../Spiro/useSpiroData/buildSpiroChartDataUtils';

export function useKolibriDayData(patientId) {
    const { day } = useDateParams(KOLIBRI_METER_ID);
    const [data, setData] = useState(null);

    const selectedDate = day && dateFormat(dateFromDateStart(day));

    const getData = () => {
        if (!selectedDate) {
            setData(null);
            return;
        }
        return getPatientDayKolibriMeasurements({
            patientId: patientId,
            date: selectedDate,
        })
            .catch((e) => {
                console.error(e?.message || e);
                return [];
            })
            .then((data) => {
                setData(data[0]);
            });
    };

    useEffect(getData, [patientId, selectedDate]);

    return { data };
}
