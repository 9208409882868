import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Iterations } from '../../../../components/Meter/Iterations/Iterations';
import { kolibriDailyStyles } from './KolibriDailyData.styles';
import { KOLIBRI_METER_ID } from '../KolibriMeter/model';

const useStyles = makeStyles(kolibriDailyStyles());

// TODO this component needs some refactoring
// - split into separate files
// - divide logic into separate components
// - split styles together with components

export const KolibriDailyData = ({ data }) => {
    const styles = useStyles();
    const [iteration, setIteration] = useState(1);

    useEffect(() => {
        if (data) {
            setIteration(1);
        }
    }, [data]);

    const items = data && data.measurements.length > 1 ? [data.avg].concat(data.measurements) : data?.measurements;

    if (!items || !items.length) {
        return <div></div>;
    }

    const amountOfIterations = items?.length || 1;
    const currentIteration = Math.min(amountOfIterations, Number(iteration));

    return (
        <div className={styles.container}>
            <DailyTable selectedDay={items[currentIteration - 1]} />
            <Iterations
                amount={amountOfIterations}
                current={currentIteration}
                id={KOLIBRI_METER_ID}
                type={1}
                onSetIteration={setIteration}
            />
        </div>
    );
};

const DailyTable = ({ selectedDay }) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const parsedDate = selectedDay.date
        ? moment(selectedDay.date)
        : moment.unix(selectedDay.timestamp).utc();

    return (
        <div>
            <div className={styles.box}>
                <div className={styles.dateContainer}>
                    {selectedDay.date && selectedDay.timestamp && <span>{parsedDate.format('hh a')}</span>}
                    {selectedDay.date && selectedDay.timestamp && <span>{parsedDate.format('DD MMM YYYY')}</span>}
                </div>
                <div>
                    <div className={styles.tableContainer}>
                        <div className={styles.tableRow}>
                            <div>{t('homePage.kolibri.inhalationQuality')}</div>
                            <div>{Math.round(selectedDay.inhalationQuality * 10) / 10}%</div>
                        </div>
                        <div className={styles.tableRow}>
                            <div>{t('homePage.kolibri.takeLongBreaths')}</div>
                            <div>{Math.round(selectedDay.takeLongBreaths * 10) / 10}%</div>
                        </div>
                        <div className={styles.tableRow}>
                            <div>{t('homePage.kolibri.breatheSlowly')}</div>
                            <div>{Math.round(selectedDay.breatheSlowly * 10) / 10}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
