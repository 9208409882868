import React, { useState, useEffect, forwardRef } from 'react';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import AdminApi from '../../../api/AdminApi';
import PhysiciansStyles from './Physicians.styles';
import { ArrowDownward } from '@material-ui/icons';

const useStyles = makeStyles(PhysiciansStyles);

const Physicians = () => {
    const styles = useStyles();
    const { t } = useTranslation();

    const tableIcons = {
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => (
            <Edit
                {...props}
                classes={{ root: styles.physicians__icon }}
                ref={ref}
            />
        )),
        Check: forwardRef((props, ref) => (
            <Check
                {...props}
                classes={{ root: styles.physicians__icon }}
                ref={ref}
            />
        )),
        Clear: forwardRef((props, ref) => (
            <Clear
                {...props}
                classes={{ root: styles.physicians__icon }}
                ref={ref}
            />
        )),
    };

    const columns = [
        {
            title: t('common.firstName'),
            field: 'firstName',
            editable: 'never',
        },
        {
            title: t('common.lastName'),
            field: 'lastName',
            editable: 'never',
        },
        {
            title: t('common.email'),
            field: 'login.email',
            editable: 'never',
        },
        {
            title: t('common.disabled'),
            field: 'login.disabled',
            type: 'boolean',
            render: (rowData) =>
                rowData.login.disabled ? (
                    <Check classes={{ root: styles.physicians__icon }} />
                ) : (
                    ''
                ),
        },
    ];

    const [data, setData] = useState([]);

    useEffect(() => {
        loadTableData();
    }, []);

    const loadTableData = () => {
        return AdminApi.getPhysiciansList()
            .then((result) => setData(result.data.result))
            .catch((e) => console.log(e));
    };

    const onRowUpdate = (newData) => {
        if (newData.login.disabled) {
            return AdminApi.disablePhysician(newData.id)
                .then(loadTableData)
                .catch((e) => console.log(e));
        }

        return AdminApi.enablePhysician(newData.id)
            .then(loadTableData)
            .catch((e) => console.log(e));
    };

    return (
        <section className={styles.physicians}>
            <MaterialTable
                options={{
                    search: false,
                    paging: false,
                    actionsColumnIndex: -1,
                }}
                title={t('adminPage.physicians')}
                icons={tableIcons}
                columns={columns}
                data={data}
                editable={{
                    onRowUpdate,
                }}
            />
        </section>
    );
};

export { Physicians };
