import { makeStyles } from '@material-ui/core';
import React from 'react';
import { DaySelect } from './DaySelect/DaySelect';
import { meterStyles } from './Meter.styles';
import { RangeSelect } from './RangeSelect/RangeSelect';

const useStyles = makeStyles(meterStyles());

const Meter = ({ title, id, children }) => {
    const styles = useStyles();

    return (
        <div className={styles.host}>
            <div className={styles.header}>
                <div>{title}</div>
                <RangeSelect id={id} />
                <div></div>
            </div>
            <DaySelect id={id}>{children}</DaySelect>
        </div>
    );
};

export { Meter };
