import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SideMenuStyles from './SideMenu.styles';

const useStyles = makeStyles(SideMenuStyles);

const MenuItem = ({ path, text, icon, isNested }) => {
    const styles = useStyles();

    return path ? (
        <NavLink
            to={path}
            exact
            className={styles.sideMenu__item}
            activeClassName={styles.sideMenu__itemActive}
        >
            <ListItem
                button
                className={isNested ? styles.sideMenu__itemChild : ''}
            >
                {icon && (
                    <ListItemIcon classes={{ root: styles.sideMenu__icon }}>
                        {icon}
                    </ListItemIcon>
                )}
                <ListItemText primary={text} />
            </ListItem>
        </NavLink>
    ) : (
        <ListItem>
            {icon && (
                <ListItemIcon classes={{ root: styles.sideMenu__icon }}>
                    {icon}
                </ListItemIcon>
            )}
            <ListItemText primary={text} />
        </ListItem>
    );
};

const SideMenu = ({ items }) => {
    const styles = useStyles();

    return (
        <Drawer
            className={styles.sideMenu}
            variant="permanent"
            classes={{
                paper: styles.sideMenu__paper,
            }}
        >
            <div className={styles.sideMenu__content}>
                <List>
                    {items.map((item, i) => (
                        <div key={i}>
                            <MenuItem
                                path={item.path}
                                text={item.text}
                                icon={item.icon}
                            />
                            {item.children && (
                                <List disablePadding>
                                    {item.children.map((child, j) => (
                                        <MenuItem
                                            key={j}
                                            path={child.path}
                                            text={child.text}
                                            isNested={true}
                                        />
                                    ))}
                                </List>
                            )}
                        </div>
                    ))}
                </List>
            </div>
        </Drawer>
    );
};

export { SideMenu };
