import React, { useState } from 'react';
import HomePageStyles from './HomePage.styles';
import { makeStyles } from '@material-ui/core/styles';
import { PatientData } from './PatientData/PatientData';
import { PatientSchedule } from './PatientSchedule/PatientSchedule';
import { SelectPatientList } from './SelectPatientList/SelectPatientList';
import { Spiro } from './Spiro/Spiro';
import { Kolibri } from './Kolibri/Kolibri';

const useStyles = makeStyles(HomePageStyles);

const SPIROMETER_CODE = 'spirometer';
const KOLIBRI_CODE = 'kolibri';

const isDeviceAvailable = (devices, deviceCode) => devices?.some((item) => item.code === deviceCode);

const HomePage = () => {
    const styles = useStyles();
    const [selectedPatient, selectPatient] = useState(null);

    const isSpirometer = selectedPatient && isDeviceAvailable(selectedPatient.devices, SPIROMETER_CODE);
    const isKolibri = selectedPatient && isDeviceAvailable(selectedPatient.devices, KOLIBRI_CODE);

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.leftContent}>
                    <div className={styles.inner}>
                        <PatientData patient={selectedPatient} />
                    </div>
                    {selectedPatient && (
                        <div className={styles.inner}>
                            <PatientSchedule patientId={selectedPatient && selectedPatient.id} />
                        </div>
                    )}
                    <div className={styles.inner}>
                        <SelectPatientList onSelectedPatient={selectPatient} />
                    </div>
                </div>
                <div className={styles.deviceContent}>
                    {selectedPatient && (
                        <>
                            {(isKolibri || selectedPatient.hasKolibriMeasurements) && <Kolibri patientId={selectedPatient.id} />}
                            {(isSpirometer || selectedPatient.hasSpiroMeasurements) && <Spiro patientId={selectedPatient.id} />}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export { HomePage };
