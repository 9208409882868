import React, {useReducer} from 'react';
import {PatientReducer, initialState} from './reducer';

const PatientStateContext = React.createContext();
const PatientDispatchContext = React.createContext();

export function usePatientState() {
    const context = React.useContext(PatientStateContext);
    if (context === undefined) {
        throw new Error("useAuthState must be used within a AuthProvider");
    }

    return context;
}

export function usePatientDispatch() {
    const context = React.useContext(PatientDispatchContext);
    if (context === undefined) {
        throw new Error("useAuthDispatch must be used within a AuthProvider");
    }

    return context;
}

export const PatientProvider = ({ children }) => {
    const [user, dispatch] = useReducer(PatientReducer, initialState);

    return (
        <PatientStateContext.Provider value={user}>
            <PatientDispatchContext.Provider value={dispatch}>
                {children}
            </PatientDispatchContext.Provider>
        </PatientStateContext.Provider>
    );
};