import { makeStyles } from '@material-ui/core';
import React from 'react';
import { daySelectStyles } from './DaySelect.styles';
import { useDateParams } from '../useDateParams';
import { DateStartPrev } from './DateStartPrev';
import { DateStartNext } from './DateStartNext';
import { Selection } from './Selection';
import { dateFromDateStart } from '../dateFromDateStart';

const useStyles = makeStyles(daySelectStyles());

const DaySelect = ({ id, children }) => {
    const styles = useStyles();
    const { dateStart, amountOfDays, day, range } = useDateParams(id);

    return (
        <div className={`${styles.host} ${range}`}>
            <div className={styles.dates}>
                <DateStartPrev id={id} dateStart={dateStart} range={range} />
                <DateStartNext id={id} dateStart={dateStart} range={range} />
            </div>
            <div className={styles.charts}>
                {children}
                <div className={styles.selection}>
                    {[...new Array(amountOfDays)].map((_, i) => (
                        <Selection
                            key={`selection_${i}`}
                            dayDate={dateFromDateStart(dateStart).add(
                                i,
                                'days',
                            )}
                            day={day}
                            range={range}
                            id={id}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export { DaySelect };
