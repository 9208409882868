const GENDERS = [
    {
        label: 'male',
        value: 0
    },
    {
        label: 'female',
        value: 1
    }
];

const ETHNICS = [
    {
        label: 'caucasian',
        value: 18
    },
    {
        label: 'africanAmerican',
        value: 19
    },
    {
        label: 'neAsian',
        value: 20
    },
    {
        label: 'seAsian',
        value: 21
    }
];

export { GENDERS, ETHNICS };
