import { makeStyles } from '@material-ui/core';
import React from 'react';
import { dateStartFormat } from '../dateStartFormat';
import { useDateParamsUrl } from '../useDateParamsUrl';
import { dateStartStyles } from './DateStart.styles';
import { Link } from 'react-router-dom';
import { dateFromDateStart } from '../dateFromDateStart';

const useStyles = makeStyles(dateStartStyles());

const DateStartPrev = ({ id, range, dateStart }) => {
    const styles = useStyles();

    return (
        <Link
            className={`${styles.host} prev`}
            to={useDateParamsUrl(id, {
                dateStart: dateStartFormat(
                    dateFromDateStart(dateStart).subtract(1, `${range}s`),
                ),
            })}
        >
            <span></span>
        </Link>
    );
};

export { DateStartPrev };
