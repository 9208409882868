import { theme } from '../../../../theme';

export const kolibriDailyStyles = (t = theme) => ({
    container: {
        boxShadow: t.palette.primary.boxShadow,
        marginTop: '1.875em',
        padding: '1.25em 1.25em 0.5em',
    },
    dateContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        height: '30px',

        '& span': {
            display: 'block',
            lineHeight: '15px',
            fontSize: '14px',
            textTransform: 'uppercase',
            color: t.palette.primary.main,
        },
    },
    tableContainer: {
        padding: '24px 0',
    },
    tableRow: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2px 10px 2px',
        borderBottom: '3px solid ' + t.palette.primary.main,

        '& div': {
            lineHeight: '27px',
            color: t.palette.primary.darkText,
            fontSize: '12px',
            flex: 1,

            '&:nth-child(2)': {
                textAlign: 'center',
            },
            '&:nth-child(3)': {
                textAlign: 'right',
            },
        },
    },
});
