const styles = () => ({
    app: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },

    content: {
        display: 'flex',
        flexGrow: '1',
    },
});

export default styles;
