import React, {useRef, useEffect} from 'react';
import FormFieldStyles from './FormField.styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(FormFieldStyles);

const FormField = ({value, onChange, type, name}) => {
    const styles = useStyles();
    const ref = useRef(null);

    useEffect(() => {
        ref.current.focus();
    }, []);


    return (
        <input ref={ref} className={styles.input} type={type} name={name} value={value} onChange={onChange} />
    );
};

export { FormField };
