export const initialState = {
    errorMessage: null
};

export const PatientReducer = (initialState, action) => {
    switch (action.type) {
        
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};