export const shadowedBox = (theme) => ({
    boxShadow: '0px 4px 8px 0px rgba(158,161,161,0.4)',
    padding: '20px',
});

export const label = (theme) => ({
    fontSize: '28px',
    lineHeight: '35px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    marginTop: 0,
    marginBottom: '42px',
});
