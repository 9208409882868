import { shadowedBox, label } from '../../../mixins';

export const patientDataStyles = (theme) => ({
    container: {
        flexGrow: 1,
    },
    box: {
        ...shadowedBox(theme),
    },
    label: {
        ...label(theme),
    },
    boxWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    boxColumn: {
        marginRight: '20px',
        flex: 1,

        '&:nth-child(2)': {
            marginRight: 0,
        },
    },
    boxColumnRow: {
        marginTop: '10px',

        '&:first-child': {
            marginTop: 0,
        },
    },
    boxLabel: {
        fontSize: '12px',
        lineHeight: '16px',
        marginBottom: '3px',
        color: theme.palette.primary.main,
    },
    boxValue: {
        fontSize: '14px',
        lineHeight: '20px',
        color: theme.palette.primary.darkText,
    },
    patientIcon: {
        width: '65px',
        height: '65x',
        borderRadius: '35px',
        border: '2px solid ' + theme.palette.primary.main,
    },
});
