import { makeStyles } from '@material-ui/core';
import React from 'react';
import { iterationsStyles } from './Iterations.styles';
import { Iteration } from './Iteration';

const useStyles = makeStyles(iterationsStyles());

export function Iterations({ id, amount, current, type, onSetIteration }) {
    const styles = useStyles();

    return (
        <div className={styles.host}>
            {[...Array(amount)]
                .map((_, i) => i + 1)
                .map((i) => (
                    <Iteration
                        key={i}
                        i={i}
                        id={id}
                        active={type === 1 || amount === 1 || current < amount - 1 ?
                            current === i :
                            (current === amount - 1 ? (current === i || current === i - 1) : (current === i || current === i + 1))}
                        type={type}
                        amount={amount}
                        onSetIteration={onSetIteration} />
                ))}
        </div>
    );
}
