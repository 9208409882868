import React from 'react';
import { patientDataStyles } from './PatientData.styles';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import patientIcon from '../../../assets/images/patient-icon.png';
import { GENDERS, ETHNICS } from './models';

const useStyles = makeStyles(patientDataStyles);

const PatientData = ({ patient }) => {
    const styles = useStyles();
    const { t } = useTranslation();

    const getEthicianLabel = () => {
        const ethnicData =
            patient && ETHNICS.find((item) => item.value === patient.ethnic);
        return ethnicData
            ? t(
                  `homePage.patientPersonalData.ethnicTypes.${ethnicData['label']}`,
              )
            : patient.ethnic;
    };

    const getGenderLabel = () => {
        const genderData =
            patient && GENDERS.find((item) => item.value === patient.gender);
        return genderData
            ? t(
                  `homePage.patientPersonalData.genderTypes.${genderData['label']}`,
              )
            : patient.gender;
    };

    return (
        <div className={styles.container}>
            <h3 className={styles.label}>
                {t('homePage.patientPersonalData.title')}
            </h3>
            <div className={styles.box}>
                {patient ? (
                    <>
                        <div className={styles.boxWrapper}>
                            <div className={styles.boxColumn}>
                                <div className={styles.boxColumnRow}>
                                    <div className={styles.boxLabel}>
                                        {t(
                                            'homePage.patientPersonalData.nameSurname',
                                        )}
                                    </div>
                                    <div className={styles.boxValue}>
                                        {patient.firstName} {patient.lastName}
                                    </div>
                                </div>

                                <div className={styles.boxColumnRow}>
                                    <div className={styles.boxLabel}>
                                        {t('homePage.patientPersonalData.wh')}
                                    </div>
                                    <div className={styles.boxValue}>
                                        {patient.height}
                                        {t(
                                            'homePage.patientPersonalData.unitCM',
                                        )}{' '}
                                        - {patient.weight}
                                        {t(
                                            'homePage.patientPersonalData.unitKG',
                                        )}
                                    </div>
                                </div>

                                <div className={styles.boxColumnRow}>
                                    <div className={styles.boxLabel}>
                                        {t(
                                            'homePage.patientPersonalData.symptoms',
                                        )}
                                    </div>
                                    <div className={styles.boxValue}>
                                        {t(
                                            'homePage.patientPersonalData.noSymptoms',
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.boxColumn}>
                                <div className={styles.boxColumnRow}>
                                    <div className={styles.boxLabel}>
                                        {t(
                                            'homePage.patientPersonalData.ethnicity',
                                        )}
                                    </div>
                                    <div className={styles.boxValue}>
                                        {getEthicianLabel()}
                                    </div>
                                </div>
                                <div className={styles.boxColumnRow}>
                                    <div className={styles.boxLabel}>
                                        {t('homePage.patientPersonalData.bd')}
                                    </div>
                                    <div className={styles.boxValue}>
                                        {moment(patient.birthday).format(
                                            'DD.MM.YYYY',
                                        )}
                                    </div>
                                </div>
                                <div className={styles.boxColumnRow}>
                                    <div className={styles.boxLabel}>
                                        {t(
                                            'homePage.patientPersonalData.gender',
                                        )}
                                    </div>
                                    <div className={styles.boxValue}>
                                        {getGenderLabel()}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={styles.boxColumnRow}>
                            <div className={styles.boxLabel}>
                                {t('common.email')}
                            </div>
                            <div className={styles.boxValue}>
                                {patient.email}
                            </div>
                        </div>
                        <div className={styles.boxColumnRow}>
                            <div className={styles.boxLabel}>
                                {t('homePage.patientPersonalData.devices')}
                            </div>
                            <div className={styles.boxValue}>
                                {patient.devices && (
                                    <>
                                        {patient.devices.map((device) => (
                                            <div>
                                                {t(
                                                    `homePage.patientPersonalData.device.${device.code}`,
                                                )}
                                                : {device.serial}
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <h5>{t('homePage.patientPersonalData.selectPatient')}</h5>
                )}
            </div>
        </div>
    );
};

export { PatientData };
