const styles = (theme) => ({
    host: {
        display: 'block',
        width: '100%',
        border: 'none',
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        textAlign: 'center',
        outline: 'none',
        padding: '7px 15px',
        borderRadius: '30px',
        fontSize: '18px',

        '&:disabled': {
            backgroundColor: theme.palette.primary.disabled,
        },
    },
});

export default styles;
