import { makeStyles } from '@material-ui/core';
import { spiroStyles } from './Spiro.styles';
import { SpiroDailyData } from './SpiroDailyData/SpiroDailyData';
import { SpiroMeter } from './SpiroMeter/SpiroMeter';
import { useSpiroData } from './useSpiroData/useSpiroData';
import { useSpiroDayData } from './useSpiroDayData';

const useStyles = makeStyles(spiroStyles());

export function Spiro({ patientId }) {
    const styles = useStyles();
    const { data } = useSpiroData(patientId);
    const { data: dayData } = useSpiroDayData(patientId);

    return (
        data && (
            <div className={styles.meterPanel}>
                <SpiroMeter data={data} />
                <SpiroDailyData data={dayData} />
            </div>
        )
    );
}
