import { useDateParams } from '../../../components/Meter/useDateParams';
import { SPIRO_METER_ID } from './SpiroMeter/model';
import { dateFromDateStart } from '../../../components/Meter/dateFromDateStart';
import { useEffect, useState } from 'react';
import { getPatientDaySpiroMeasurements } from '../../../api/patientApi';
import { dateFormat } from './useSpiroData/buildSpiroChartDataUtils';

const prepareMeasurement = (item) => {
    const prepared = {};
    prepared.timestamp = item.timestamp;
    prepared.date = item.date;
    const rawData = item.data;
    prepared.PEF = rawData.PEF;
    prepared.FEV1 = rawData.FEV1;
    prepared.FEV1FVC = rawData.FEV1FVC;
    prepared.FVC = rawData.FVC;
    prepared.target = rawData.target;
    prepared.comment = item.comment;
    prepared.rate = item.rate;
    prepared.FlowData = rawData.FlowData;
    return prepared;
};

const groupSpiroResultsByDate = (results) => {
    return results.reduce((rv, item) => {
        const prepared = prepareMeasurement(item);
        const dataOfTheDay = rv[item.date] || {
            date: item.date,
            best: prepared,
            measurements: [],
        };

        if (prepared.FEV1 > dataOfTheDay.best.FEV1) {
            dataOfTheDay.measurements.push(dataOfTheDay.best);
            dataOfTheDay.best = prepared;
        } else {
            if (rv[item.date]) {
                dataOfTheDay.measurements.push(prepared);
            }
        }
        rv[item.date] = dataOfTheDay;
        return rv;
    }, {});
};

export function useSpiroDayData(patientId) {
    const { day } = useDateParams(SPIRO_METER_ID);
    const [data, setData] = useState(null);

    const selectedDate = day && dateFormat(dateFromDateStart(day));

    const getData = () => {
        if (!selectedDate) {
            setData(null);
            return;
        }
        return getPatientDaySpiroMeasurements({
            patientId: patientId,
            date: selectedDate,
        })
            .catch((e) => {
                console.error(e?.message || e);
                return [];
            })
            .then((data) => {
                const res = Object.values(groupSpiroResultsByDate(data));
                setData(res[0]);
            });
    };

    useEffect(getData, [patientId, selectedDate]);

    return { data };
}
