import React, {useState, useEffect} from 'react';
import { patientScheduleStyles } from './PatientSchedule.styles';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {getPatientSchedule} from "../../../api/patientApi";
import {PatientTreatmentItem} from './PatientTreatmentItem';

const useStyles = makeStyles(patientScheduleStyles);

function useScheduleData(patientId) {
    const [data, setData] = useState(null);

    const getData = () => {
        return getPatientSchedule(patientId)
            .catch((e) => {
                console.error(e?.message || e);
                return [];
            })
            .then((data) => {
                setData(data);
            });
    };

    useEffect(getData, [patientId]);

    return { data };
}

const PatientSchedule = ({ patientId }) => {
    const styles = useStyles();
    const { t } = useTranslation();

    const { data } = useScheduleData(patientId);

    return (
        <div className={styles.container}>
            <h3 className={styles.label}>
                {t('homePage.patientSchedule.title')}
            </h3>
            <div className={styles.box}>
                {data && (
                    <>
                        {data.map((treatment) => (
                            <PatientTreatmentItem key={treatment.id} treatment={treatment} />
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};

export { PatientSchedule };
