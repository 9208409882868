import { LoginPage } from '../pages/Login/LoginPage';
import { HomePage } from '../pages/Home/HomePage';
import { AdminPage } from '../pages/Admin/AdminPage';

const routes = [
    {
        path: '/login',
        component: LoginPage,
        needAuth: false,
        needAdminPerm: false,
    },
    {
        path: '/admin',
        component: AdminPage,
        needAuth: true,
        needAdminPerm: true,
    },
    {
        path: '/',
        component: HomePage,
        needAuth: true,
        needAdminPerm: false,
    },
];

export default routes;
