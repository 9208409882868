import React from 'react';
import { Line, ReferenceLine, XAxis, YAxis } from 'recharts';
import { theme } from '../../../../theme';
import { LineChart } from '../../../../components/Chart/LineChart';
import { LineBlueFilter } from '../../../../components/Chart/LineBlueFilter';

const SpiroLineChart = ({
    data: {
        title,
        target,
        series: [seriesPresent, seriesAbsent, seriesFuture],
        referenceLines,
    },
}) => {
    const reference100Label = (
        <text>
            <tspan x="100.5%" y="20%">
                {title}
            </tspan>
            <tspan x="100.5%" dy="1.4em">
                {target.toFixed(2).replace('.', ',')}
            </tspan>
        </text>
    );

    return (
        <LineChart>
            <defs>
                <LineBlueFilter />
            </defs>

            <XAxis
                dataKey="x"
                type="number"
                domain={[0, 'dataMax']}
                hide={true}
            />
            <YAxis
                dataKey="y"
                type="number"
                domain={[
                    (dataMin) => Math.floor(dataMin) - 5,
                    (dataMax) => Math.ceil(dataMax) + 5,
                ]}
                axisLine={false}
                tickLine={false}
                unit="%"
                width={30.2}
                ticks={referenceLines}
                interval={0}
            />

            {seriesPresent.map((series, i) => (
                <Line
                    key={`present${i}`}
                    data={series}
                    dataKey="y"
                    type="monotone"
                    dot={false}
                    strokeWidth={2}
                    stroke={theme.palette.primary.main}
                    // TODO find out why it removes flat lines
                    // filter={lineBlueFilter}
                />
            ))}

            {seriesAbsent.map((series, i) => (
                <Line
                    key={`absent${i}`}
                    data={series}
                    dataKey="y"
                    type="monotone"
                    dot={false}
                    strokeWidth={2}
                    strokeDasharray="8 8"
                    stroke={theme.palette.primary.chart.spiro.absent}
                />
            ))}

            {seriesFuture.map((series, i) => (
                <Line
                    key={`future${i}`}
                    data={series}
                    dataKey="y"
                    type="monotone"
                    dot={false}
                    strokeWidth={2}
                    stroke={theme.palette.primary.chart.spiro.future}
                />
            ))}

            {referenceLines.map((line, i) => (
                <ReferenceLine
                    key={`referenceLine${i}`}
                    y={line}
                    stroke={theme.palette.primary.chart.spiro.referenceLine}
                    strokeWidth={1}
                    strokeDasharray={line !== 100 ? '9 5' : 'none'}
                    label={line === 100 ? reference100Label : null}
                />
            ))}
        </LineChart>
    );
};

export { SpiroLineChart };
