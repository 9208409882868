const styles = (theme) => {
    return {
        testUsers: {
            width: 600,
        },

        testUsers__icon: {
            color: theme.palette.text.secondary,
        },
    };
};

export default styles;
