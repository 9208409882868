export const headerStyles = (theme) => {
    return {
        host: {
            display: 'flex',
            padding: '25px 50px',
            backgroundColor: theme.palette.primary.main,
            justifyContent: 'space-between',
            alignItems: 'center',
        },

        logo: {
            width: '124px',
            height: '48px',
        },
        logout: {
            cursor: 'pointer',
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
        },
        logoutIcon: {
            width: '24px',
            height: '24px',
        },
        buttons: {
            display: 'flex',
            alignItems: 'center',
        },
        langButton: {
            cursor: 'pointer',
            padding: '0 10px',
            border: 'none',
            outline: 'none',
            fontSize: '16px',
            backgroundColor: 'transparent',
            color: '#FFF',
            textTransform: 'uppercase',

            '&:hover': {
                color: '#eee',
            },
        },
        logoutContainer: {},
        profile: {
            color: '#ffffff',
            marginRight: '2em',
        },
        rightSide: {
            display: 'flex',
            alignItems: 'center',
        },
    };
};
