import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SPIRO_METER_ID } from './model';
import { spiroMeterStyles } from './SpiroMeter.styles';
import { SpiroLineChart } from './SpiroLineChart';
import { Meter } from '../../../../components/Meter/Meter';

const useStyles = makeStyles(spiroMeterStyles());

function SpiroMeter({ data }) {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <Meter id={SPIRO_METER_ID} title={t('homePage.spirometerTitle')}>
            {data.charts.map((chart, i) => (
                <div key={i} className={styles.chart}>
                    <SpiroLineChart data={chart} />
                </div>
            ))}
        </Meter>
    );
}

export { SpiroMeter };
